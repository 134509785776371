.loading {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);

  .spinner {
    -webkit-animation: spinner .5s linear infinite;
    animation: spinner .8s linear infinite;
    border-color: #000 rgba(0,0,0,.1) rgba(0,0,0,.1);
    border-radius: 50%;
    display: inline-block;
    border-width: 2px;
    border-style: solid;
  }
}

@keyframes spinner {
  0% {
    transform: translate3d(0,0,0) rotate(0);
  }

  100% {
    transform: translate3d(0,0,0) rotate(359deg);
  }
}