.grid {
  input, textarea {
    font-family: -apple-system, system-ui, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen-Sans, sans-serif, 'Apple Color Emoji';
  }

  .grid-header-cell {
    cursor: pointer;

    &:hover {
      background-color: #f2f3f3;
    }
  }

  &.scrolled {
    .grid-header-freeze, .grid-container-freeze {
      box-shadow: 0 0 5px #bdbbbb;
    }
  }

  .grid-header-freeze,
  .grid-container-freeze {
    z-index: 2;

    .grid-header-cell:first-child {
      border: 0;
    }
  }

  .grid-container-freeze {
    .grid-row {
      .grid-cell:first-child {
        border-right: 0;
      }
    }
  }

  &-cell-order-num {
    padding-right: 30px;
    position: relative;

    span {
      flex-grow: 1;
      text-align: center;
    }

    .color {
      border-radius: 4px;
      width: 6px;
      height: 100%;
      flex: 0 0 auto;
      margin: 0 3px;
    }

    .expand {
      position: absolute;
      right: 4px;
      align-items: center;
      background: #fff;
      border: none;
      height: 26px;
      cursor: pointer;
      outline: none;
      border-radius: 50%;
      display: none;

      .icon {
        font-size: 13px;
        color: #1639e6;
      }

      &:hover {
        background: #e5e7f9;
      }
    }
  }

  .row--hover {
    .grid-cell-order-num .expand {
      display: flex;
    }
  }

  .grid-group {
    overflow: visible;

    &-name {
      font-size: 10px;
      text-transform: uppercase;
    }

    &-renderer {
      color: #121212;

      .grid-cell-value {
        padding: 6px 0;
      }
    }

    &-column {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      box-sizing: border-box;
      border-right: 1px solid #cbcfd0;
      cursor: pointer;

      &:hover {
        background: #f1efef;
      }
    }

    .agg-value {
      > span {
        color: #919191;
        padding-right: 5px;
      }
    }

    &-summary {
      display: flex;
      width: 100%;
      height: 100%;
      align-items: center;
      justify-content: flex-end;
      padding: 8px;

      .agg {
        > span {
          color: #919191;
          padding-right: 5px;
        }
      }

      &:hover {
        .agg-hint {
          display: flex;
        }
      }
    }

    &-main {
      cursor: pointer;

      .grid-group-summary {
        position: absolute;
        right: 5px;
        top: 0;
        height: 100%;
        max-width: 50%;
        display: flex;
        align-items: center;
        padding-left: 35px;
        background: -moz-linear-gradient(to right, hsla(0, 0%, 97%, 0), hsl(0, 0%, 97%) 18%);
        background: -webkit-linear-gradient(to right, hsla(0, 0%, 97%, 0), hsl(0, 0%, 97%) 18%);
        background: linear-gradient(to right, hsla(0, 0%, 97%, 0), hsl(0, 0%, 97%) 18%);
      }

      &:hover {
        background: #f1efef;
      }
    }

    &-header {
      .agg-hint {
        display: none;
        color: #868686;

        .icon {
          font-size: 19px;
        }
      }
    }

    &-expand {
      &:hover {
        background: #f1efef;
      }
    }
  }

  &-header {
    background: #fff;
    border-top: 1px solid #cbcfd0;
    font-weight: 500;
  }

  &-cell {
    background: #fff;

    &-value {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .re-value {
    position: relative;
    padding: 0 6px;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    display: flex;
    align-items: center;

    &.email, &.phone {
      > span {
        color: rgba(0, 0, 0, 0.65);
        border-bottom: 1px solid rgba(0, 0, 0, 0.65);
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        line-height: 14px;

        a {
          color: rgba(0, 0, 0, 0.65);
        }
      }

      .email-to {
        display: none;
        width: 23px;
        height: 23px;
        position: absolute;
        right: 3px;
        top: 50%;
        background: #fff;
        text-align: center;
        transform: translateY(-50%);
        border-radius: 4px;
        box-shadow: 0px 0px 3px #848584;
        color: #848584;
        transition: .2s all;

        &:hover {
          box-shadow: 0px 0px 1px #848584;
        }
      }

      &:hover {
        .email-to {
          display: block;
        }
      }
    }

    &.number {
      text-align: right;
    }
  }

  .lookup-value {
    flex: 1 1 auto;
    flex-direction: row;
    flex-wrap: nowrap;
    width: 100%;
    height: 100%;
    display: flex;
    overflow: hidden;

    > div {
      display: flex;
      margin-right: 20px;
    }
  }

  .grid-multiple {
    text-overflow: unset;
  }

  &-action {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    .eyzy-btn {
      height: 24px;
      white-space: nowrap;
    }
  }

  .grid-mask__editing {
    border: 0;

    input, textarea {
      border: 2px solid #2d7ff9;
      border-radius: 0;
      padding: 5px;
      background: #fff;
    }
  }

  &-mask-container {
    width: 100%;
    background: #fff;
    padding: 6px;
    height: 100%;
    box-sizing: border-box;
    display: flex;
    align-items: center;
  }

  &-value {
    color: rgba(0, 0, 0, 0.65);
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    line-height: 14px;
    padding: 6px 0;
    margin: 0 6px;
    background: #fff;
  }

  &-centered {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }

  &-mask__active {
    .grid-cell-value {
      padding: 2px 6px;
      height: 100%;
      background: #fff;
    }

    .re-value {
      background: #fff;
      padding: 0 5px;
      height: 100%;
    }
  }

  &-action {
    z-index: 3;
  }

  .re-value {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .column-def {
    height: 100%;
  }

  .re-editor {
    width: 100%;
    height: 100%;
    background: #fff;

    &.ant-select {
      min-height: 100%;
      height: auto;
    }
  }

  &-link-record {
    height: 100%;
    padding: 4px 6px;
    display: flex;
    background: #fff;

    .add-record {
      outline: none;
      cursor: pointer;
      background: #eee;
      margin-right: 8px;
      border: 0;
      padding: 0 4px;

      .icon {
        line-height: 1;
      }

      &:hover {
        background: #d6d6d6;
      }
    }
  }

  .react-datepicker-wrapper {
    width: 100%;
    height: 100%;
  }

  .react-datepicker__input-container {
    height: 100%;

    input {
      border: 0;
      width: 100%;
      height: 100%;
      padding: 0 6px;
    }
  }

  .add {
    display: block;
    width: 100%;
    text-align: left;
    height: 31px;
    background: none;
    border: 0;
    cursor: pointer;
    outline: none;
    box-sizing: border-box;

    &:hover {
      background: #F8F8F8;
    }
  }

  &.grouped {
    .add {
      border: 0 !important;
    }
  }

  &.freeze {
    .grid-footer {
      .add {
        border-right: 1px solid #dde1e3;
      }
    }

    .grid-viewport-scroll {
      .grid-footer {
        .icon {
          //visibility: hidden;
        }
      }
    }
  }

  &-footer {
    .add {
      border-left: 1px solid #dde1e3;
      border-right: 1px solid #dde1e3;
      border-bottom: 1px solid #dde1e3;
    }
  }

  &-header {
    &-cell {
      button.add {
        border: 0;
        text-align: center;
      }

      .grid-text {
        height: 100%;
        flex: 1 0 100%;

        // ебанный antd
        > div {
          height: 100%;
          width: 100%;
          line-height: 32px;
        }

      }
    }
  }

  &-scroller {
    &::-webkit-scrollbar {
      width: 10px;
      height: 10px;
      background-color: transparent;
    }

    &::-webkit-scrollbar-thumb {
      background-color: hsla(0, 0%, 0%, 0.28);
      border-radius: 6px;

      &:hover {
        background-color: hsla(0, 0%, 0%, 0.4);
      }
    }
  }
}

@media (max-width: 770px) {
  .grid-cell-order-num {
    padding: 0;

    .expand {
      display: none;
    }
  }
}
