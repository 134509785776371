.workspace-container__view-list {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.view-selector {
  display: flex;
  cursor: pointer;

  & > .view-name {
    font-weight: bold;
    display: flex;
  }
}

.view-name {
  .icon {
    margin-right: 5px;
  }
}

.view-list {
  width: 420px;
  background: #fff;
  box-shadow: 0 0 0 2px rgba(0,0,0,0.1);
  border-radius: 4px;

  &-container {
    padding: 10px;
    margin: 0;
  }

  &-actions {
    padding: 11px 18px;
    display: flex;
    align-items: center;
    border-top: 1px solid #e8e8e8;

    span {
      font-size: 0.8em;
      color: #6f6e6e;
      margin-right: 15px;
    }

    ul {
      display: flex;
      margin: 0;

      button {
        background: none;
        border: 0;
        cursor: pointer;
        opacity: .8;
        display: flex;
        align-items: center;

        &:hover {
          opacity: 1;
        }
      }

      .icon {
        margin-right: 5px;
        font-size: 1.4em;
      }
    }
  }

  &-item {
    font-size: 1.1em;
    line-height: 1.1em;
    padding: 0 10px 0 25px;
    display: flex;
    width: 100%;
    position: relative;
    align-items: center;

    &:hover {
      background: #eee;
      border-radius: 4px;
    }

    .mdi-check {
      position: absolute;
      left: 2px;
      top: 50%;
      transform: translateY(-50%);
    }

    .icon {
      margin-right: 6px;
      font-size: 1.4em;
    }

    button {
      display: block;
      color: #000c17;
      flex-grow: 1;
      padding: 7px 2px;
      background: 0;
      border: 0;
      text-align: left;
      outline: none;
      cursor: pointer;

      &:hover {
        color: #000c17;
      }
    }
  }
}