.irv-popover {
  min-width: 30px;
  min-height: 32px;
  background: #fff;
  box-shadow: 0 3px 6px -4px rgb(0 0 0 / 12%), 0 6px 16px 0 rgb(0 0 0 / 8%), 0 9px 28px 8px rgb(0 0 0 / 5%);
  opacity: 0;
  transition: transform 0.25s cubic-bezier(0,0,0.4,1), opacity 0.25s cubic-bezier(0,0,0.4,1);
  border-radius: 4px;
  overflow: hidden;

  &.open {
    opacity: 1;
  }
}
