@import "../../../assets/style/variables";

.widgets-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 0;

  .widget-item {
    flex: 0 1 49%;
  }
}

.widget {
  &-item {
    color: #323232;
    display: flex;
    flex: 1 1 auto;
    align-items: center;
    background: #f4f4f4;
    overflow: hidden;
    padding: 8px;
    border: 1px solid #d7d7d7;
    border-radius: 6px;
    cursor: pointer;
    margin-bottom: 10px;
    outline: none;
    transition: transform .1s ease-out;

    &:active {
      transform: scale(0.98);
    }

    &:hover {
      background: #e5e3e3;
    }

    .shape {
      margin-right: 10px;
      height: 40px;
      flex: 1 0 40px;
      width: 40px;
    }
  }

  &-form {
    &-descr {
      margin-bottom: 1em;
      opacity: 0.8;
    }

    &-header {
      margin-bottom: 30px;
      padding: 0;

      .eyzy-tabs {
        &-header {
          border: 0;
        }

        &-arrow {
          display: none;
        }

        &-header__item {
          border: 0;
          display: flex;
          align-items: center;
          padding: 0 5px;

          &:first-child {
            padding-left: 15px;
          }

          &:last-child {
            padding-right: 15px;
          }

          > span {
            padding: 9px 14px;
            border-radius: 16px;

            &:hover {
              background: #efefef;
            }
          }

          &.active {
            > span {
              background: #0a0a0a;
              color: #fff;
            }
          }
        }
      }
    }

    &-missed-conf {
      display: flex;
      align-items: center;
      flex-direction: column;
    }

    &-item {
      margin-bottom: 20px;
    }

    &-ext {
      .e-form {
        margin-top: 35px;
      }
    }


    .label {
      color: #9f9f9f;
      margin-bottom: 5px;

      &.required {
        &:after {
          content: '*';
          font-size: 1.2em;
          color: #ff396d;
          padding: 0.2em;
        }
      }
    }

    .re-editor {
      width: 100%;
    }

    .eyzy-input {
      height: 2.28571em;
    }

    .error {
      margin-bottom: 2px;
      color: #f72e50;
      font-size: 0.9em;
    }

    .renderer {
      color: #434343;
    }
  }

  &-modal {
    &.ant-drawer, &.ant-modal {
      z-index: $z-widget-modal;
    }

    &__forms-title {
      margin-bottom: 20px;
    }

    .success {
      font-weight: bold;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      img {
        margin-bottom: 30px;
      }
    }

    .retry {
      text-align: center;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      p {
        font-weight: bold;
        font-size: 1.4em;
      }
    }

    .add-record {
      margin-bottom: 10px;
    }
  }
}

@media screen and (max-width: 800px) {
  .widget-form-header {
    padding: 0;
    margin-bottom: 40px;

    .eyzy-tabs {
      &-header {
        border: 0;
      }

      &-arrow {
        display: none;
      }

      &-header__item {
        border: 0;
        display: flex;
        align-items: center;
        padding: 0 5px;

        &:first-child {
          padding-left: 15px;
        }

        &:last-child {
          padding-right: 15px;
        }

        > span {
          padding: 9px 14px;
          border-radius: 16px;
        }

        &.active {
          > span {
            background: #0a0a0a;
            color: #fff;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 500px) {
  .widgets-container {
    flex-wrap: nowrap;
    padding: 10px 10px 10px 25px;
    overflow: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
    justify-content: flex-start;

    &::-webkit-scrollbar {
      display: none;
    }

    .mf {
      flex: 0 0 25px;
    }

    .widget {
      &-item {
        flex-direction: column;
        justify-content: center;
        margin: 0 15px 0 0;
        flex: 0 0 35%;
        height: 120px;

        .shape {
          margin: 0;
        }

        .truncate {
          text-align: center;
          font-size: 0.8em;
          margin-top: 8px;
        }

        &:last-child {
          margin-right: 30px;
        }
      }

      &-modal {
        .react-datepicker-wrapper {
          width: 100%;
        }

        &__forms-title {
          overflow: auto;

          .ant-radio-group {
            display: flex;
            flex-wrap: nowrap;
          }

          .ant-radio-button-wrapper {
            flex: 1 0 auto;
          }
        }
      }
    }
  }

  .widget-form {
    &-header {
      overflow: auto hidden;
      -ms-overflow-style: none;
      scrollbar-width: none;

      &::-webkit-scrollbar {
        display: none;
      }
    }
  }
}
