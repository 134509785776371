.rows-table {
  overflow: auto;
  position: relative;
  padding: 0 10px 10px 10px;

  table {
    position: relative;
    table-layout: fixed;
    width: 100%;
    border: 0;
  }

  thead {
    position: sticky;
    top: 0;
    z-index: 2;
    background: #f8f8f9;

    th:first-child .header {
      border-left: 1px solid #c9c9cb;
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
    }

    th:last-child .header {
      border-right: 1px solid #c9c9cb;
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
    }
  }

  tbody {
    tr {
      height: 70px;

      &:hover {
        background: #ededee;
      }

      &:active {
        background: #e5e5e7;
      }
    }

    td {
      padding: 15px;
    }
  }

  th {
    text-align: center;
    padding: 0;
  }

  tr {
    &:nth-child(even) {
      background-color: #fafafb;
    }
  }

  td, th {
    overflow: hidden;
  }

  .header {
    display: flex;
    height: 40px;
    align-items: center;
    color: #8b8b8b;
    padding: 0 15px;
    box-sizing: border-box;
    border-top: 1px solid #c9c9cb;
    border-bottom: 1px solid #c9c9cb;
  }
}
