@import '../../assets/style/variables';

$slugHeight: 90px;

.linked-entries {
  &-modal {
    .t-name {
      margin-bottom: 20px;
    }

    .no-records {
      color: $gray-color;
    }

    .irv-modal-header {
      padding: 8px 16px;

      .e-field {
        margin: 0;
        padding-right: 40px;
      }

      .e-input {
        box-shadow: none;
        border: 0;
        background: #fff;
      }
    }

    .irv-modal-content {
      min-height: 110px;
      position: relative;
      overflow: hidden auto;
      display: flex;
      flex-direction: column;
    }

    .e-table {

      td {
        cursor: pointer;
      }
    }

    .ant-collapse-header {
      display: flex;

      .e-checkbox {
        margin-right: 10px;
      }
    }

    .ant-drawer-close {
      padding: 15px 25px;
    }

    .group-header {
      display: flex;
      align-items: center;

      .checkbox-wrap {
        display: block;
        width: 18px;
        height: 18px;
        margin-right: 10px;
      }
    }

    .r-color {
      min-width: 6px;
      margin-right: 15px;
      border-radius: 4px;
    }
  }

  &-list {
    button.rm {
      width: 32px;
      height: 32px;
      border: 1px solid #cecece;
    }

    .e-table-s {
      button.rm {
        height: 26px;
        width: 26px;
      }
    }
  }

  &-item {
    height: $slugHeight;
    border: 2px solid #e5e5e5;
    border-radius: 4px;
    box-sizing: border-box;
    margin-bottom: 10px;
    cursor: pointer;
    position: relative;

    .body {
      display: flex;
      overflow: auto;
      padding: 10px;
      width: 100%;
      height: 100%;
    }

    &.selected {
      border-color: #798fee;
    }

    .e-checkbox {
      margin-right: 15px;
    }
  }

  &-col {
    margin-right: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-width: 20%;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    .col-name {
      color: $secondary-color;
    }

    .col-value {
      .grid-value {
        margin: 0;
      }

      .grid-number {
        text-align: left;
      }
    }
  }
}

.linked-entries-btn + .linked-entries {
  > .linked-entries-list {
    margin-top: 20px;
  }
}

.no-entries {
  color: $gray-color;
  padding: 0 20px;
}

@media (min-width: 700px) {
  .linked-entries {
    &-modal {
      margin: 50px 0;
      max-height: calc(100% - 100px);
    }
  }
}

@media (max-width: 700px) {
  .linked-entries {
    &-modal {
      --irv-table-cell-padding: 12px;

      .irv-modal-content {
        padding: 0;

        > .irv-table {
          height: 100%;
          border-radius: 0;
          border-left: 0;
          border-right: 0;

          .irv-group-header {
            border-radius: 0;
          }
        }
      }

      .t-name {
        padding: 15px 0 0 15px;
      }

      .e-table {
        height: 100%;
        border-radius: 0;
      }
    }
  }
}
