@import "../../../common/style/variables.scss";

.e-field {
  flex-direction: column;
  display: inline-flex;

  &.fit {
    display: flex;
    width: 100%;
  }

  &.error {
    .e-input {
      border-color: #ff396d;
    }

    .e-ring {
      box-shadow: 0 0 0 1px #ff396d;
      border-color: #ff396d;

      &:focus {
        box-shadow: 0 0 0 1px #ff396d;
        border-color: #ff396d;
      }
    }
  }

  .e-ring {
    box-shadow: 0 0 0 1px #2680eb;
    border-color: #2680eb;

    &:focus {
      box-shadow: 0 0 0 1px #2680eb;
      border-color: #2680eb;
    }
  }
}

.e-asterisk {
  color: #ff396d;
  padding-left: 3px;
  font-size: 1.2em;
}

.e-label {
  display: -ms-flexbox;
  display: flex;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  box-sizing: border-box;
  color: $label-color;
  font-size: $label-font-size;
  font-weight: $label-font-weight;
  line-height: $label-line-height;
  vertical-align: top;
  -webkit-font-smoothing: subpixel-antialiased;
  -moz-osx-font-smoothing: auto;
  margin-bottom: 5px;
}

.e-error {
  color: rgb(201, 37, 45);
  font-weight: 400;
  padding-top: 4px;
  font-size: 0.9em;
}

.e-help {
  font-weight: 300;
  line-height: 20px;
  white-space: pre-wrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.e-ctrl {
  position: relative;
}

