.e-form {
  padding: 0 32px;
  display: flex;
  flex-direction: column;

  // ЭТО УДАЛИТЬ ПОТОМ НАХУЙ
  .ant-select {
    width: 100%;

    &-selector {
      min-height: 40px;
      border-radius: 4px !important;

      > .ant-select-selection-item {
        display: flex;
        padding: 5px 0;
      }

      .ant-select-selection-search {
        > input {
          height: 100% !important;
        }
      }
    }
  }


  .linked-entries-btn {
    border: 1px solid #cdcdcd;
  }

  .e-date {
    .e-btn {
      border: 1px solid #cdcdcd;
    }
  }

  .e-field {
    &.error {
      .ant-select {
        &-selector {
          border-color: #ff396d;
        }
      }
    }
  }

  .e-btn {
    height: 40px;
  }
}


.e-label {
  font-size: 1.1em;
  color: #000;
  margin-bottom: 15px;
  font-weight: 600;
  line-height: 20px;
  white-space: pre-wrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.e-field {
  margin-bottom: 40px;
  width: 50%;

  &:last-child {
    margin-bottom: 0;
  }

  .e-ctrl, .e-input {
    min-height: 40px;
  }

  .bti > span {
    margin-left: 5px;
  }
}

.e-date {
  .icon {
    margin-right: 10px;
  }

  > .icon {
      position: absolute;
      right: 15px;
      top: 50%;
      transform: translateY(-50%);
      pointer-events: none;
  }
}


@media (max-width: 767px) {
  .e-field {
    width: 100%;

    &:last-child {
      margin-bottom: 30px;
    }
  }

  .e-label {
    display: inline-flex;
  }

  .ant-select-selector {
    background: #f9f9f9 !important;
  }

  .e-input, .e-textarea {
    &:focus {
      box-shadow: 0 0 0 2px rgba(24, 115, 230, .4);
    }
  }
}
