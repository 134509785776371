.fp {
  &-header {
    color: #666666;
    margin-bottom: 10px;
  }

  &-body + &-footer {
    margin-top: 20px;
  }

  &-col {
    width: 150px;
  }

  &-item {
    .eyzy-btn {
      border: 1px solid #d9d9d9;
      background-color: #fff;
    }

    .eyzy-btn, .fp-c.eyzy-input {
      height: 32px;
    }
  }

  &-op {
    width: 150px;
  }

  &-item, &-ctrl, &-a {
    display: flex;
    flex-wrap: nowrap;
  }

  &-item {
    margin-top: 10px;
  }

  &-dop {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 65px;
    padding-right: 5px;

    .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
      padding: 0 6px;
      font-size: 10px;
    }

    .ant-select-item-option-content {
      font-size: 10px;
    }
  }

  &-ctrl {
    flex-grow: 1;

    > div {
      margin-right: 5px;
    }

    .ant-select {
      &-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
        box-shadow: none;
        -webkit-box-shadow: none;
        border-color: #d9d9d9;
      }
    }
  }

  &-value {
    flex: 1 1 auto;
    min-width: 150px;
    align-items: center;
    display: flex;

    .eyzy-tag {
      font-size: 0.8em;
    }
  }

  &-cbx {
    height: 100%;
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &-a {
    flex-grow: 0;

    .eyzy-btn {
      border: 0;
    }
  }

  .eyzy-input {
    height: 32px;
    border-color: #d9d9d9;
  }

  &-c.eyzy-input {
    border-color: #d9d9d9;
  }

  &-dmode {
    display: flex;

    .ant-select {
      width: 100%;
    }

    .eyzy-input {
      width: 50px;
    }

    .fp-c {
      width: 100px;
    }

    .react-datepicker-wrapper, .eyzy-input {
      margin-left: 5px;
    }
  }

  .ant-select {
    width: 100%;
    font-size: 11px;

    &-single:not(.ant-select-customize-input) .ant-select-selector {
      padding: 0 6px;
    }

    &-item-option-content {
      font-size: 12px;
    }

    &:not(.ant-select-disabled) .ant-select-selector {
      &:hover {
        background-color: #f6f6f6;
        border-color: #d9d9d9;
      }
    }
  }
}

.fs {
  display: flex;
  flex-wrap: nowrap;

  span {
    margin: 0 3px;
  }
}

@media (max-width: 480px) {
  .df {
    .eyzy-btn {
      background: #dedede;
    }
  }
}