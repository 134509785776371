.tl {
  &-resource {
    padding: 0 40px;
    font-weight: 600;
  }

  &-event {
    padding: 5px 2px;
    border-radius: 4px;

    &-content {
      border-radius: 4px;
    }
  }

  &-row {
    border-color: #dfe1e6;

    &-hover {
      background: #e6e6e6;
    }
  }

  &-cell {
    background-color: rgba(244, 245, 247, 0.56);
    border-color: #dfe1e6;
  }

  &-header {
    .tl-column {
      padding: 0 30px;
      font-weight: bold;
    }

    &-pr-row {
      &:last-child {
        .tl-header-pr-cell {
          font-weight: 600;
        }
      }
    }

    &-pr-cell {
      padding: 3px 0;
      border-right: 1px solid #dfe1e6;

    }
  }

  &-group {
    background: rgb(244, 245, 247);
  }

  &-resources-columns {
    overflow: hidden;
  }

  .we {
    background-color: rgba(215, 215, 216, 0.50);
  }

  .re-value {
    white-space: nowrap;
    overflow: hidden;
    width: 100%;
    padding: 2px;
  }
}