.irv-modal {
  display: flex;
  flex-direction: column;
  background: #fff;
  max-width: 90vw;
  min-width: 300px;
  transition: transform 0.25s cubic-bezier(0,0,0.4,1), opacity 0.25s cubic-bezier(0,0,0.4,1);
  z-index: 2;
  opacity: 0;
  pointer-events: none;
  visibility: hidden;
  background-clip: padding-box;
  border: 0;
  position: relative;
  border-radius: 2px;
  box-shadow: 0 3px 6px -4px rgb(0 0 0 / 12%), 0 6px 16px 0 rgb(0 0 0 / 8%), 0 9px 28px 8px rgb(0 0 0 / 5%);
  transform: translateY(30px);

  &-fullscreen {
    height: 100%;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    position: fixed;
    max-width: unset;
    max-height: unset;
    transform: translateY(100%);
    transition: transform 0.15s ease-out, opacity 0.25s cubic-bezier(0,0,0.4,1);
  }

  &.open {
    visibility: visible;
    opacity: 1;
    pointer-events: auto;
    transform: translateY(0);
    transition-delay: 0s;
  }

  &-wrapper {
    display: flex;
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 3;
    left: 0;
    top: 0;
    align-items: center;
    justify-content: center;
    pointer-events: none;
    padding: 50px 0;
  }

  &-header {
    padding: 16px 24px;
    color: rgba(0, 0, 0, 0.85);
    background: #fff;
    border-bottom: 1px solid #f0f0f0;
    border-radius: 2px 2px 0 0;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  &-content {
    padding: 24px;
    overflow: hidden auto;
    flex-grow: 1;
    width: 100%;
  }

  &-title {
    flex: 1 1 auto;

    h3 {
      margin: 0;
    }
  }

  &-footer {
    padding: 16px 24px;
    display: flex;
    align-items: center;
    border-top: 1px solid #f0f0f0;
  }
}

.irv-underlay {
  &.open {
    opacity: .9999;
  }

  &.tr {
    background: transparent;
  }

  visibility: visible;
  pointer-events: auto;
  transition-delay: 0s;
  position: fixed;
  inset: 0;
  overflow: hidden;
  z-index: 3;
  opacity: 0;
  background: rgba(0, 0, 0, .4);
  transition: opacity 0.15s cubic-bezier(0,0,0.4,1);
}

