.color-picker {
  > button {
    display: flex;
    width: 20px;
    height: 20px;
    border: 0;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    cursor: pointer;
    outline: none;

    &:hover {
      &:before {
        content: '';
        display: block;
        position: absolute;
        width: 20px;
        height: 20px;
        background: rgba(0,0,0,0.1);
        border-radius: 50%;
      }
    }
  }

  .color-palette {
    width: 280px;
  }

  .ant-tooltip {
    max-width: unset;
  }

  .ant-tooltip-inner {
    padding: 0;
    background: #fff;
    color: #343434;
    font-size: 0.9em;
    position: relative;
  }

  .ant-tooltip-arrow {
    display: none;
  }

}

.color-palette {
  display: flex;
  overflow: auto;
  flex-wrap: wrap;
  justify-content: space-around;
  padding: 3px;

  > span {
    margin: 1px 1px;
    height: 23px;
    flex: 1 1 8%;
    border: 1px solid #f1efef;
    cursor: pointer;

    &.active:before {
      content: '';
      display: block;
      width: 100%;
      height: 100%;
      background: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNnB4IiBoZWlnaHQ9IjE2cHgiIHZpZXdCb3g9IjAgMCA0OCA0OCIgZmlsbD0iI0ZGRkZGRiI+PHBhdGggZD0iTTAgMGg0OHY0OEgweiIgZmlsbD0ibm9uZSIvPjxwYXRoIGQ9Ik0xOCAzMi4zNEw5LjY2IDI0bC0yLjgzIDIuODNMMTggMzhsMjQtMjQtMi44My0yLjgzeiIvPjwvc3ZnPg==) center no-repeat;
    }
  }
}
