@import "../../../common/style/variables.scss";
@import "../../../common/style/mixins.scss";

.eyzy-tabs {
  display: flex;
  flex-direction: column;
  overflow: hidden;

  &.vertical {
    flex-direction: row;

    .eyzy-tabs-scroll {
      flex-direction: column;
    }

    .eyzy-tabs-header {
      border-right: 1px solid #eee;
      border-bottom: 0;

      &__item {
        &.active {
          border-bottom-color: transparent;
          border-right: 2px solid;
        }
      }
    }
  }

  &-wrap {
    flex: 1 1 auto;
    overflow: auto hidden;
    display: flex;

    &::-webkit-scrollbar {
      width: 0;
      height: 0;
    }
  }

  &-scroll {
    display: flex;
  }

  &-arrow {
    border: 0;
    outline: none;
    background: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    flex: 0 0 16px;

    &.l {
      transform: rotate(180deg);
    }

    &.disabled {
      cursor: default;
      opacity: 0.3;
    }
  }

  &-header {
    border-bottom: 1px solid #eee;
    display: flex;
    overflow: hidden;

    &__item {
      cursor: pointer;
      padding: 10px;
      color: #5f5f5f;
      position: relative;
      border-bottom: 2px solid transparent;
      white-space: nowrap;

      &:hover {
        color: #101010;
      }

      &.active {
        border-bottom-color: $primary;
        color: #101010;
      }
    }
  }

  &-content {
    display: flex;
    flex-grow: 1;
    padding: 15px 10px;

    &__item {
      width: 100%;
      flex: 1 1 auto;
    }
  }
}