@import "../../../../assets/style/variables";

.hello-workspace {
  background: #fcfcfc;
  -webkit-transition: background-color .4s;
  transition: background-color .4s;
  padding: 15px;

  &.animate {
    background: #ececec;
  }

  &__name {
    display: flex;
    align-items: center;
    font-size: 1.4em;
    cursor: pointer;

    h3 {
      border-width: 3px;
      display: inline-block;
      margin: 0;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      padding: 5px 0;
    }

    .bordered {
      border-bottom: 3px solid rgba(0, 0, 0, 0.1);
      cursor: pointer;
    }

    &:hover {
      .bordered {
        border-color: rgb(51, 51, 51);
      }

      .icon {
        opacity: 1;
      }
    }
  }

  &__header {
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .icon {
      opacity: .6;
      line-height: 1;
      margin-left: 5px;
    }
  }

  &__title {
    overflow: hidden;
    margin-right: 40px;
  }

  &__share {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
      .hello-workspace__avatars .avatar {
        margin-left: 2px;
      }
    }

    .eyzy-tag {
      color: #fff;
      background: #1ac52d;
    }
  }

  &__avatars {
    margin: 0 10px 0 0;
    position: relative;
    display: flex;
    flex-direction: row-reverse;

    .avatar {
      margin-left: -12px;
      transition: margin 0.1s ease-out;
    }
  }

  &__items {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 0;

    li {
      flex: 0 0 49%;
      display: flex;
      align-items: center;
      box-sizing: border-box;
      border-radius: 6px;
      transition: .085s all ease-in;
      padding-right: 10px;
      overflow: hidden;

      &:hover {
        background-color: rgba(0, 0, 0, 0.05);

        .bo {
          display: flex;
        }
      }
    }

    .hello-link {
      color: $primary;
      display: flex;
      flex: 1 1 auto;
      align-items: center;
      border: 0;
      background: none;
      overflow: hidden;
      padding: 10px;

      &__add {
        cursor: pointer;

        .shape {
          background: rgba(0, 0, 0, 0.08);
        }
      }

      .shape {
        .icon {
          font-size: 25px;
          color: #fff;
        }
      }
    }
  }

  &__bseparator {
    margin: 30px 0 20px;
    color: $gray-color;
  }

  .bo {
    flex: 0 0 30px;
    height: 30px;
    display: none;
  }

  .b-lock {
    .icon {
      font-size: 0.8em;
    }
  }

  .b-edit {
    color: #7e7d7d;

    &:hover {
      color: #575757;
    }
  }

  .b-lock {
    display: flex;
  }

  .shape {
    border-radius: 6px;
    margin-right: 10px;
    width: 40px;
    height: 40px;
    flex: 0 0 auto;
  }
}
