.avatar {
  border-radius: 50%;
  overflow: hidden;
  width: 30px;
  height: 30px;
  display: inline-block;
}

.profile {
  display: flex;

  &-avatar {
    cursor: pointer;
  }

  &-modal {
    .irv-modal-content {
      padding: 0;
    }

    .irv-modal-footer {
      a {
        margin-left: auto;
      }

      button {
        margin-right: 10px;
      }
    }

    &-menu {
      flex: 0 0 250px;
    }

    &-content {
      flex: 1 1 auto;
      padding: 20px;
    }
  }

  &-block {
    padding-bottom: 10px;
    border-bottom: 1px solid #dadada;
    margin-bottom: 10px;

    &:last-child {
      border: 0;
    }

    &-input {
      display: flex;
      flex-direction: column;
      margin: 0 0 13px;

      label {
        text-transform: uppercase;
        letter-spacing: 0.07em;
        color: rgba(53, 52, 49, 0.49);
        font-weight: 500;
      }
    }

    &-avatar {
      display: flex;
      flex-direction: column;
      width: 150px;
      align-items: center;

      .preview {
        width: 85px;
        height: 85px;
        overflow: hidden;
        border-radius: 50%;
      }

      .eyzy-btn {
        margin-top: 10px;
      }

      input {
        display: none;
      }
    }
  }
}


@media screen and (max-width: 600px) {
  .profile-modal {
    &-footer {
      padding: 0;
    }
  }
}
