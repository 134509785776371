.order-ctrl {
  height: 32px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  background: #efefef;
  cursor: pointer;
  border: 2px solid #e8e5e5;
  border-radius: 4px;
  position: relative;
  padding: 2px;

  span {
    padding: 0 10px;
    border-radius: 4px;
    height: 100%;
    display: flex;
    align-items: center;
    width: 60px;
    z-index: 2;
    white-space: nowrap;

    &:hover {
      opacity: 0.7;
    }

  }

  &.asc .asc-label {
    color: #fff;
  }

  &.desc .desc-label {
    color: #fff;
  }

  &.desc {
    &:after {
      left: 60px;
    }
  }

  &:after {
    content: '';
    position: absolute;
    height: 26px;
    width: 60px;
    background: #666;
    z-index: 1;
    border-radius: 4px;
  }

}