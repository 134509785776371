@import "../../assets/style/variables";

.hello {
  background: #fcfcfc;
  width: 100%;
  height: 100%;
  color: $primary;
  display: flex;
  flex-direction: column;
  align-items: center;

  &-container {
    width: 100%;
    height: 100%;
    padding: 35px 35px 0;
    overflow: auto;
  }

  &-wrapper {
    max-width: $max-width;
    position: relative;
    margin: 0 auto;
  }

  &-menu {
    position: fixed;
    width: 250px;
    top: 110px;
    bottom: 20px;
    overflow: auto;

    &__workspaces {
      padding-bottom: 10px;

      > ul {
        padding-right: 15px;
        margin-bottom: 15px;
      }
    }

    &__add {
      margin-bottom: 30px;
    }
  }

  &-workspaces {
    height: 100%;
    margin-left: 260px;
    overflow: auto;

    .hello-workspace {
      margin-bottom: 50px;
    }

    .widgets-container {
      margin: 30px 0 20px;
    }
  }

  .add-workspace {
    background: none;
    border: 0;
    color: $primary;
    opacity: .6;
    font-size: 1.3em;
    cursor: pointer;
    display: flex;
    align-items: center;
    margin: 0 0 30px 15px;
    padding: 0;
    outline: none;

    .icon {
      font-size: 1.6em;
      margin-right: 8px;
    }

    &:hover {
      opacity: 1;
    }
  }
}

@media screen and (max-width: 900px) {
  .hello {
    &-menu {
      display: none;
    }

    &-workspaces {
      margin: 0;
    }
  }
}

@media screen and (max-width: 600px) {
  .hello {
    &-container {
      background: $mobile-bg;
      padding: 0;
    }

    .widget-item {
      background: #fbfbff;
      border: 0;
      border-radius: 10px;
      box-shadow: 0 0 2px rgba(0, 0, 0, 0.1);
    }

    &-workspace {
      background: $mobile-bg;
      padding: 20px 0;

      &__title {
        margin: 0;
      }

      &__name {
        h3 {
          white-space: break-spaces;
          line-height: 1.1em;

          &.bordered {
            border: 0;
          }
        }
      }

      &__header, &__body {
        padding: 0 25px;
      }

      &__menu-dd {
        display: none;
      }

      &__items {
        background: #fff;
        padding: 0;
        border-radius: 6px;
        overflow: hidden;
        flex-direction: column;

        .add {
          display: none;
        }

        li {
          background: #fff;
          width: 100%;
          margin: 0;
          box-sizing: border-box;
          border-radius: 0;

          &:hover {
            box-shadow: none;
            background: none;
          }

          &:active {
            background-color: rgb(228, 228, 228);
          }
        }
      }

      &__share {
        display: none;
      }
    }

    .add-workspace {
      font-size: 1em;
    }
  }
}
