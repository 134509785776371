.irv-frm {
  --irv-border-width: 2px;
  --irv-select-bg: #fff;
  --irv-select-border-open: var(--irv-border-focus);
  --irv-select-bg-open: #fff;

  display: flex;
  flex-direction: column;

  &-header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
    flex-direction: column;
  }

  &-inline {
    --irv-border: #e4e4e4;
    --irv-border-hover: #f4f4f4;
    --irv-border-width: 1px;
    --irv-border-focus-width: 1px;
    --irv-border-focus: #d2d2d2;
    --irv-select-border-open: #d2d2d2;
    --irv-input-bg-hover: #f4f4f4;
    --irv-input-bg-focus: #f4f4f4;
    --irv-select-bg-hover: #f4f4f4;
    --irv-select-border-hover: transparent;

    &.irv-frm--ro {
      .irv-frm-value {
        > div {
          padding: 0 10px;
        }

        .lr {
          padding: 0;
        }
      }

      .cc {
        .e-checkbox {
          padding: 0;
        }
      }
    }

    .irv-frm-row {
      width: 100%;
      flex-direction: row;
      margin: 0;
      min-height: 40px;

      & + .irv-frm-row {
        margin-top: 1em;
      }
    }

    .irv-frm-label {
      flex: 0 0 auto;
      width: 170px;
      align-items: center;
      display: flex;
      margin: 0;
      font-size: .9em;
      font-weight: 400;
    }

    .irv-frm-value {
      flex: 1 1 auto;
      align-items: center;
      display: flex;

      &:hover {
        background: #f4f4f4;
      }
    }

    .fr, .cd, .mo {
      padding: 10px;
    }

    .cc {
      width: 100%;
      height: 100%;
      cursor: pointer;

      .e-checkbox {
        display: flex;
        width: 100%;
        height: 100%;
        padding: 10px;
        box-sizing: border-box;
      }
    }

    .lr {
      overflow: hidden;
      width: 100%;

      > .e-btn {
        width: 100%;
        justify-content: flex-start;
        border: 0;
        background: 0;
      }
    }

    .irv-select-trigger {
      padding: 0 10px;
    }
  }

  &-row {
    margin-bottom: 30px;

    &:hover {
      .suffix {
        display: flex;
      }
    }

    &.required {
      .irv-frm-label {
        &:after {
          content: '*';
          font-size: 1.2em;
          color: #ff396d;
          padding: 0.2em 0 0 0.2em;
        }
      }
    }
  }

  &-value {
    position: relative;

    > textarea.tl {
      min-height: 120px;
    }

    > .el, > .lk, > .ph {
      padding-right: 40px;
    }
  }

  &-label {
    font-weight: 600;
  }

  .e-input {
    height: 40px;

    &:focus {
      + .suffix {
        display: flex;
      }
    }
  }

  .irv-select-trigger {
    height: auto;
    min-height: 40px;
  }

  .link {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  &-error {
    margin-top: 6px;
    color: #ff396d;
  }

  .error {
    .e-input, .e-btn.linked-entries-btn, .irv-select-trigger {
      border-color: #ff396d;
    }
  }

  .irv-table {
    .us {
      overflow: hidden;

      .e-tag {
        max-width: 100%;
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }

    .ss, .ms {
      .e-tag {
        max-width: 100%;
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }
  }

  .linked-entries-btn {
    border: 1px solid #e3e3e3;
  }

  .suffix {
    display: none;
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    align-items: center;
    justify-content: center;
    width: 50px;
    color: #000;
    font-weight: 500;

    .e-btn {
      width: 24px;
      height: 24px;
      border: 1px solid #cac9c9;
    }
  }

  .irv-table {
    .fixed {
      td.row-cell > div {
        display: flex;
        flex-wrap: nowrap;
      }
    }
  }
}


@media (max-width: 767px) {
  .irv-frm {
    .suffix {
      display: flex;
    }

    &-inline {
      .irv-frm-label {
        margin-bottom: 7px;
      }

      .irv-frm-row {
        flex-direction: column;
        margin-bottom: 20px;
      }
    }
  }
}
