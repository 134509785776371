@use "variables";
@use "../iorvo-font/style.css";
@use "normalize";
@use "utils";
@use "grid";
@use "colors";
@use "animations";
@use "WILL_BE_REMOVED";
@use "antd-styles";
@use "externals";
@use "forms";
@use "renderers";
@use "../material/materialdesignicons.min.css";

$hover-color: #cdccca;

*,
*::before,
*::after {
  box-sizing: border-box; // 1
}

:root {
  --irv-form-ctrl-height: 40px;
  --irv-form-ctrl-border: #cdcdcd;

}

@media screen and (max-width: 700px) {
  :root {
    --irv-form-ctrl-height: 50px;
  }
}

.irv {
  &-modalwt {
    max-width: 1024px;
  }

  &-frm {
    &-row + .irv-frm-row {
      margin-top: 15px;
    }

    &-row {
      display: flex;
      flex-direction: column;

      &--inline {
        flex-direction: row;
        justify-content: space-between;

        .irv-frm-label {
          margin: 0;
        }
      }
    }

    &-description {
      color: #8b8888;
      margin-bottom: 15px;
    }

    &-label {
      font-size: 1.2em;
      color: var(--irv-form-label-color, #000);
      margin-bottom: 15px;
      line-height: 20px;
      white-space: pre-wrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}

.icon {
  font-size: 13px;
}

.i {
  &-caret-down {
    font-size: 15px;
  }
}

.secondary-text {
  color: #8a8a8a;
}

.error-text {
  color: red;
  margin: 5px 0;
}

.section-text {
  color: #aaa9a7;
  padding: 10px 20px;
  font-size: 0.9em;
  text-transform: uppercase;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.eyzy-tabs {
  &-wrap {
    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }
  }
}

.eyzy-user {
  &-badge.e-tag {
    position: relative;
    height: 24px;
    margin-top: 3px;

    &.removed {
      opacity: .4;
    }

    img {
      position: absolute;
      border-radius: 50%;
      left: -1px;
      top: -1px;
    }

    .icon {
      position: absolute;
      border-radius: 50%;
      margin-right: 5px;
      font-size: 22px;
      top: 5px;
      left: 2px;
    }

    .name {
      padding-left: 20px;
    }

    &.nn {
      margin-right: 10px;
    }
  }
}

.eyzy-textarea {
  overflow: auto;
  resize: vertical;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  height: 2.28571em;
  line-height: 2.28571em;
  box-sizing: border-box;
  color: #333;
  background-color: #fff;
  padding: 0 10px;
  outline: none;
  border: 1px solid #efefef;
  box-sizing: border-box;
  transition: all 0.1s ease-out 0s;

  &:focus {
    border-color: #d9d9d9;
    box-shadow: none;
  }

  &-fit {
    width: 100%;
  }
}

.removed {
  &.eyzy-tag {
    opacity: .6;
  }
}

.remove {
  background: none;
  border: 0;
  width: 20px;
  height: 20px;
  padding: 0;
  cursor: pointer;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;

  &[disabled] {
    opacity: .5;
  }

  .i-cross-small {
    font-size: 18px;
    font-weight: bold;
  }
}

.ant-badge, .eyzy-tag {
  margin-right: 4px;
  border-radius: 10px;
  white-space: nowrap;
}

.re-editor {
  &.checkbox {
    display: flex;
    justify-content: center;
  }
}

// expand/collapse
.exp {
  width: 20px;
  height: 20px;
  background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='19.2' height='19.2' viewBox='0 0 16 16'%3E%3Cpath fillRule='evenodd' fill='currentColor' d='M4.80153237,6.00002 L11.1984676,6.00002 C11.8579881,6.00002 12.2338734,6.76912179 11.8387119,7.30861993 L8.64024427,11.6724979 C8.3194337,12.1091674 7.6805663,12.1091674 7.3611326,11.6724979 L4.1612881,7.30861993 C3.76612658,6.76912179 4.14201193,6.00002 4.80153237,6.00002'%3E%3C/path%3E%3C/svg%3E") no-repeat center center;
  outline: none;
  border: 0;
  cursor: pointer;
}

// dots....
.tc {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.bti {
  > span {
    margin-left: 10px;
  }
}


// TODO: REMOVE
// button with an icon
.bi {
  border-radius: 4px;
  border: 0;
  background: none;
  outline: none;
  cursor: pointer;
  color: #4d4d4d;
  transition: background-color 0.2s ease-out;
  align-items: center;
  justify-content: center;
  display: flex;

  .icon {
    font-size: 13px;
  }

  &:hover {
    background: $hover-color;
  }
}

// TODO: REMOVE
.bo {
  border: 0;
  background: none;
  outline: none;
  cursor: pointer;
  color: #4d4d4d;
  transition: background-color 0.2s ease-out;
  align-items: center;
  justify-content: center;
  display: flex;
}

p.no {
  color: #7b7b7b;
  padding: 10px 0;
}

// TODO: REMOVE
// button icon NO styles
.bim {
  border: 0;
  background: none;
  outline: none;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  display: flex;
  padding: 0;

  .icon {
    opacity: .8;
    font-size: 13px;
  }

  &:hover {
    background: none;

    .icon {
      opacity: 1;
    }
  }
}

.no-data {
  color: rgba(0, 0, 0, .5);
}

.fl {
  flex-grow: 1;
}

.column-def {
  display: flex;
  flex: 1 1 auto;
  align-items: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  justify-content: space-between;

  .icon {
    margin-right: 8px;
    color: #aba3a3;
    font-size: 15px;
  }
}

.din {
  background-color: #f7f7f7;
}

.sbr ::-webkit-scrollbar {
  width: 15px;
  height: 15px
}

.sbr ::-webkit-scrollbar-thumb {
  background-color: #c2c2c2;
  border-radius: 10px;
  background-clip: content-box
}

.sbr ::-webkit-scrollbar-thumb:hover {
  background-color: #7d7d7d;
  border-radius: 10px;
  background-clip: content-box
}

.sbr ::-webkit-scrollbar-thumb:vertical, .sbr ::-webkit-scrollbar-thumb:vertical:hover {
  border: 3px solid transparent;
  border-left-width: 4px
}

.sbr ::-webkit-scrollbar-thumb:horizontal, .sbr ::-webkit-scrollbar-thumb:horizontal:hover {
  border: 3px solid transparent;
  border-top: 4px solid transparent
}

.sbr ::-webkit-scrollbar-track {
  background-color: #fafafa
}

.sbr ::-webkit-scrollbar-track:vertical {
  border-left: 1px solid #e8e8e8
}

.sbr ::-webkit-scrollbar-track:horizontal {
  border-top: 1px solid #e8e8e8
}

.sbr ::-webkit-scrollbar-corner {
  background-color: #fafafa
}

.nsb {
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
}

.df {
  display: flex;
  align-items: center;
  justify-content: center;
}

.irv-modal {
  &-footer {
    .e-btn {
      height: 36px;
    }

    .e-btn + .e-btn {
      margin-left: 10px;
    }
  }
}

.link {
  color: #3280f4;
  text-decoration: underline;
  outline: 0;
  font-weight: 500;

  &:hover {
    color: #3280f4;
    text-decoration: none;
  }
}

.s-icon {
  margin-right: 8px;
}

@media screen and (max-width: 700px) {
  .irv-modal {
    &-header {
      padding: 8px 10px 8px 26px;
    }

    &-footer {
      height: 120px;
      align-items: flex-start;
    }
  }
}

