@import "../../../assets/style/variables";

.ws {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  display: flex;

  &-sb, &-content {
    height: 100%;
    position: relative;
  }

  &-sb {
    width: $sidebarWidth;
    flex: 0 0 auto;
    transition: width 0.15s ease-out;
    display: flex;
    flex-direction: column;
    border-right: 1px solid #c2c0c8;
  }

  &-content {
    overflow: hidden;
    flex: 1 1 auto;
    background: $bgMainContent;
  }

  &-header {
    display: flex;
    align-items: center;
    padding: 0 20px;
    background: $bgHeader;
    border-bottom: 1px solid #dddcda;
    height: 50px;

    &-nav {
      display: flex;
      align-items: center;
      flex: 1 1 auto;
      width: 100%;
      overflow: hidden;

      .eyzy-tabs {
        overflow: hidden;

        &-header {
          border: 0;

          &__item {
            height: 50px;
            display: flex;
            align-items: center;
            border-width: 3px;
          }
        }

        .eyzy-tabs-header__item ~ .eyzy-tabs-header__item {
          margin-left: 5px;
        }
      }

      .dl {
        margin: 0 10px;
      }
    }

    .base-link {
      .icon {
        width: 30px;
        height: 30px;
      }
    }

    .table-link {
      margin-left: 10px;
      white-space: nowrap;
      flex: 0 1 auto;
    }

    .view-selector {
      flex-grow: 1;
    }

    .menu {
      display: none;
      margin: 0 10px;
    }

    .add-view {
      white-space: nowrap;
      margin: 0 10px;

      .icon {
        font-size: 16px;
        font-weight: bold;
      }
    }
  }

  &-table {
    position: absolute;
    top: 50px;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    background: $bgView;
  }

  &-view {
    flex: 1 1 0;
    position: relative;
    margin-top: 5px;
    border-top: 1px solid $uiBorder;

    &.clr {
      .irv-sn {
        padding-left: 20px;
      }
    }

    &-header {
      flex: 0 0 50px;
      display: flex;
      align-items: center;
      padding: 0 15px;
      margin-top: 5px;
    }

    &-hbase {
      display: flex;

      .df {
        margin-left: 5px;
      }
    }

    .no-data {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }

  &.sb--collapsed {
    .cha, .che {
      display: none;
    }

    .menu {
      display: flex;
    }

    .sb-header {
      padding: 0;
    }

    .sb-logo {
      margin-bottom: 30px;
    }

    .logo {
      width: 30px;
    }
  }

  .bi {
    width: 30px;
    height: 30px;
  }

  .calendar-toolbar {
    border-top: 1px solid #e2e2e2;
  }
}

@media (min-width: 1024px) {
  .ws {
    &.sb--collapsed {
      .ws {
        &-sb {
          width: 50px;
        }
      }

      .sb {
        .collapse {
          left: 35px;
          transform: translateX(7px) rotate(180deg);
        }

        &-logo {
          > a {
            transform: translate(9px, 5px);
          }
        }

        &-footer {
          justify-content: center;

          .icon {
            transform: rotate(180deg);
          }

          span {
            display: none;
          }
        }

        &-actions {
          padding: 10px 5px;
          margin-bottom: 20px;
          border-bottom: 1px solid #c9c9d2;
        }

        &-act {
          padding: 9px 10px;

          span {
            display: none;
          }
        }

        &-ws {
          &:not(.expanded) {
            display: none;
          }

          &-name {
            display: none;
          }
        }

        &-base {
          margin-bottom: 2px;

          &-title {
            padding: 0;
          }
        }

        &-bases {
          padding: 5px;
        }

        &-tables {
          display: none;
        }
      }
    }
  }
}

@media (max-width: 1024px) {
  .ws {
    &.sb--collapsed {
      .sb {
        width: 0;
        overflow: hidden;

        .collapse {
          display: none;
        }
      }
    }

    &-header {
      padding: 0;
      border: 0;

      .add-view {
        display: none;
      }
    }
  }
}

@media screen and (max-width: 800px) {
  .ws {
    &-header {
      .base-link {
        .tc {
          display: none;
        }
      }

      .eyzy-tabs {
        &-arrow {
          display: none;
        }

        &-header__item {
          border: 0;

          &.active {
            .view-name {
              background: #0a0a0a;
              color: #fff;
              padding: 8px 12px;
              border-radius: 16px;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 480px) {
  .ws {
    background: #f2f3f6;

    &-sb {
      &:before {
        position: absolute;
        left: 0;
        top: 0;
        z-index: 99;
        background: transparent;
        width: 100%;
        height: 100%;
        transition: background .2s ease-out;
      }
    }

    &.sb--collapsed {
      .sb {
        &:before {
          content: '';
          display: block;
          background: #fff;
        }
      }
    }

    &-sidebar {
      position: absolute;
    }

    &-table {
      top: 50px;
    }

    .ws-content {
      &:before {
        position: absolute;
        left: 0;
        top: 0;
        height: 0;
        content: '';
        z-index: 99999;
        transition: background-color 0.2s ease-out, width 0.05s;
        user-select: none;
      }
    }

    .bi .icon {
      font-size: 20px;
    }

    .calendar-toolbar {
      .today {
        display: none;
      }
    }

    .ew-header {
      font-size: 10px;
    }
  }
}

