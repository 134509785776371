.irv-select {
  display: inline-flex;
  position: relative;

  &.quiet {
    padding: 0;

    .irv-select-trigger {
      background: none;
      border: 0;
      padding: 0;
    }
  }

  &-underlay {
    opacity: .9999;
    position: fixed;
    inset: 0;
  }

  &.open {
    .irv-select-trigger {
      z-index: 10000;
      background: var(--irv-select-bg-open, #f4f4f4);
      border-color: var(--irv-select-border-open, var(--irv-border));
    }
  }

  &.fit {
    width: 100%;
  }

  &-white {
    .irv-select-trigger {
      background: #fff;
    }
  }

  &-trigger {
    background: var(--irv-select-bg, #fafafa);
    border-style: solid;
    border-width: var(--irv-border-width, 1px);
    border-color: var(--irv-border);
    border-radius: 4px;
    min-height: 32px;
    height: var(--irv-form-ctrl-height, 32px);
    padding: 0 12px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    transition: all ease .1s;
    flex: 1 1 auto;
    overflow: hidden;

    &:focus {
      border-color: var(--irv-border-focus);
    }

    &[disabled] {
      background: #e0e0e0;
      cursor: not-allowed;

      &:hover {
        background: #e0e0e0;
      }
    }

    &.multiple {
      > input {
        flex: 1 0 100px;
        position: relative;
        background: mediumvioletred;
        max-width: 100px;
      }
    }

    &:hover {
      background: var(--irv-select-bg-hover, #fff);
      border-color: var(--irv-select-border-hover, rgb(179, 179, 179))
    }

    svg.icon {
      height: 6px;
      flex: 0 0 12px;
      margin: 0 5px;
    }

    .search {
      display: block;
      height: 100%;
      position: absolute;
      border: 0;
      box-sizing: border-box;
      background: transparent;
      left: 7px;
      right: 40px;
      outline: none;
      flex: 1 1 auto;
    }

    .clear {
      position: absolute;
      right: 10px;
      top: 0;
      height: 100%;
      width: 22px;
      padding: 0;
      border: 0;
      background: transparent;
      display: flex;
      align-items: center;
      justify-content: center;

      .icon {
        font-size: 16px;
        font-weight: bold;
        opacity: .6;

        &:hover {
          opacity: 1;
        }
      }
    }
  }

  &-value {
    flex: 1 1 auto;
    flex-wrap: wrap;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    min-width: 0;
    overflow: hidden;
  }

  &-box {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    position: relative;
    max-height: inherit;
    z-index: 2;

    .no-data {
      padding: 10px 0 0 10px;
    }
  }

  &-items {
    padding: 4px 0;
    overflow: hidden auto;
    flex: 1;
    width: 100%;
  }

  &-header, &-footer {
    flex: 0 0 auto;
  }

  &-header {
    .search {
      width: 100%;
      display: block;
      border: 0;
      box-sizing: border-box;
      background: transparent;
      outline: none;
      flex: 1 1 auto;
      padding: 8px;
    }
  }

  &-item {
    outline: none;
    position: relative;
    min-height: var(--irv-select-item-height, 32px);

    .irv-item, .irv-bitem {
      min-height: var(--irv-select-item-height, 32px);
    }

    &.active {
      background: #f7f7f7;

      .irv-bitem {
        &:before {
          content: '';
          position: absolute;
          left: 0;
          top: 0;
          height: 100%;
          width: 2px;
          background: #0e65eb;
        }
      }
    }

    &.selected {
      background: #f7f7f7;

      .irv-bitem {
        &:before {
          content: '';
          position: absolute;
          left: 0;
          top: 0;
          height: 100%;
          width: 2px;
          background: #0e65eb;
        }
      }
    }

    .mark {
      position: absolute;
      right: 15px;
      top: 50%;
      transform: translateY(-50%);
    }
  }
}

@media screen and (max-width: 700px) {
  .irv-select {
    &-box {
      padding-bottom: 30px;
    }

    &-item {
      border-bottom: 1px solid #eee;

      &:active {
        background: #f7f7f7;
      }
    }
  }
}
