$headerBorderColor: #a9a9a9;
$cellBorderColor: #dde1e3;

$scrollBarWidth: 12px;

$groupHeaderBG: #f7f7f7;
$groupHeaderBG1: #ededed;
$groupHeaderBG2: #e3e3e3;

$groupHeaderBorder: #cacaca;
$groupHeaderBorder1: #c1c1c1;
$groupHeaderBorder2: #bdbdbd;

$groupHeaderBorderColor: #cbcfd0;
