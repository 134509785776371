@import "../../../assets/style/variables";

$modalPadding: 24px;

.widget-modal {
  .irv-modal {
    &-content {
      padding: 0;
    }
  }

  .irv-frm {
    padding: 20px 32px;
  }

  .form {
    padding: 0 32px;

    &-header {
      display: flex;
      justify-content: space-between;

      .remove {
        width: 35px;
        height: 35px;

        .icon {
          font-size: 20px;
        }
      }
    }
  }

  .form ~ .form {
    margin-top: 20px;
  }

  .no {
    padding: 20px;
  }
}

.widget-form {
  &-container {
    padding: 15px 0;
    position: relative;
    height: 100%;
    min-height: 250px;

    > .ant-radio-group {
      margin: 0 $modalPadding 20px $modalPadding;
    }

    > .ant-collapse {
      padding: 0 8px;
    }

    .pr {
      margin-top: 30px;
    }
  }

  &-ans {
    margin-top: 25px;
    padding: 20px 25px;
    background: #f9f9f9;
    border-top: 1px solid #efefef;
    border-bottom: 1px solid #efefef;

    button {
      margin-right: 10px;
      border: 1px solid #e1dcdc;
    }
  }

  &-footer {
    display: flex;
    margin-top: 30px;
    padding: 0 $modalPadding;
  }
}

@media screen and (max-width: 700px) {
  .widget-modal {
    --irv-form-ctrl-height: 40px;
    --irv-input-bg: #f9f9f9;
    --irv-input-bg-focus: #f4f4f4;
  }

  .widget-form {
    &-container {
      position: initial;

      .add-record {
        border: 1px solid #d0d0d0;
      }

      .eyzy-input {
        width: 100%;
      }
    }
  }

  .widget-modal {
    .react-datepicker-wrapper {
      width: 100%;
    }

    .form {
      padding: 0 $modalPadding;

      &-selector {
        overflow: auto;
      }

      &-item-label {
        margin: 0 10px;
        color: #111111;
        font-size: 0.9em;
      }

      &-item-ctrl {
        background: #fff;
        min-height: 35px;
        border: 1px solid #f5f5f8;
        border-radius: 6px;
        display: flex;
        align-items: center;
      }

      input, textarea {
        border: 0;
      }

      .checkbox {
        width: 100%;
        height: 35px;
      }

      .nb {
        width: 100%;

        div {
          padding: 0 10px;
        }
      }

      .ant-select:not(.ant-select-customize-input) .ant-select-selector {
        border: 0;
        height: 100%;
      }

      .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
        box-shadow: none;
      }

      .eyzy-checkbox {
        width: 100%;
        padding-left: 10px;
      }

      .re-value {
        padding: 10px;
      }

      .lr {
        width: 100%;
      }

      .add-record + .linked-records {
        margin-top: 10px;
      }

      .linked-records {
        padding: 10px;
      }

      .add-record {
        border: 0;
        background: none;
        width: 100%;
        height: 35px;
        margin: 0;
        justify-content: flex-start;
      }
    }

    .eyzy-radio-group {
      height: 46px;
      background: #e2e1e1;

      > label:active {
        color: #78859a;
      }
    }

    .ant-drawer-body {
      margin-bottom: 80px;
    }
  }
}
