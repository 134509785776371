.c {
  &-toolbar {
    padding: 0 10px;
  }

  &-event {
    &.past {
      opacity: .8;
    }
  }

  &-body {
    background: #fff;
  }

  &-recurring {
    &-head {
      h3 {
        margin-bottom: 20px;
      }
    }

    &-controls {
      display: flex;
      flex-direction: column;
      margin-top: 30px;

      .eyzy-btn {
        margin-top: 7px;
      }

      .cancel {
        margin-top: 20px;
      }
    }
  }

  &-toolbar {
    .today {
      border: none;
      background: transparent;
      border-radius: 4px;
      padding: 0 10px;
      height: 30px;
      cursor: pointer;

      &:hover {
        background: #f9f9f9;
      }
    }
  }

  .nav-dates {
    margin: 0 20px;
  }

  .nav-views {
    margin-left: auto;
  }

  .nav-ctrl {
    background: transparent;
    border: 0;
    width: 50px;
    height: 30px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;

    .icon {
      font-size: 20px;
    }
  }
}

.ct-events {
  width: 240px;
  padding: 10px;
}

.ct-date {
  font-weight: bold;
  padding: 10px;
}

.ct-hr {
  height: 6px;
}

.event {
  &-card {
    position: absolute;
    background: #fff;
    z-index: 100;
    border-radius: 4px;
    box-shadow: 0 24px 38px 3px rgba(0, 0, 0, 0.14), 0 9px 46px 8px rgba(0, 0, 0, 0.12), 0 11px 15px -7px rgba(0, 0, 0, 0.2);
    width: 510px;
    max-height: 700px;
    transform: translateX(-10px);
    transition: all 0.2s;
    opacity: 0;
    box-sizing: border-box;
    padding: 15px 20px;

    &-footer {
      margin-top: 40px;
      display: flex;

      .delete-btn {
        margin-left: auto;

        .icon {
          margin-right: 5px;
        }
      }

      .cancel-btn {
        margin-left: 7px;
      }
    }

    &.o {
      transform: translateX(0);
      opacity: 1;
    }
  }

  &-details {
    &-row {
      margin-top: 25px;
      display: flex;
      align-items: center;

      .label {
        font-weight: bold;
        margin-right: 10px;
      }
    }

    &-repeat {
      .calendar-weekdays {
        margin-left: 10px;
      }
    }
  }
}

@media screen and (max-width: 500px) {
  .c-event {
    &-title {
      white-space: normal;
      word-break: break-all;
    }

    &-time {
      display: none;
    }
  }

  .event {
    &-details {
      &-row {
        flex-wrap: wrap;
      }

      .calendar-weekdays {
        margin: 20px 0 0;
      }
    }

    &-card {
      &-footer {
        margin: 0 0 40px 0;
      }
    }
  }
}
