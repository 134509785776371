@import "../../../common/style/variables.scss";
@import "../../../common/style/mixins.scss";

.eyzy-btn-group {
  @include flex-inline();

  &-fit {
    display: flex;

    .eyzy-btn {
      flex: 1 1 auto;

      &:not(:last-child) {
        border-right: 1px solid #fff;
      }
    }
  }

  .eyzy-btn {
    border-radius: 0;
    margin: 0;

    &:first-child {
      border-top-left-radius: $border-radius;
      border-bottom-left-radius: $border-radius;
    }
    
    &:last-child {
      border-top-right-radius: $border-radius;
      border-bottom-right-radius: $border-radius;
    }
  }
}