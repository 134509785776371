@import "../../../../assets/style/variables";

$paddingLeft: 8px;

.hello-menu {
  h3 {
    opacity: .6;
    letter-spacing: .1em;
    font-size: .8em;
    margin-bottom: 10px;
    padding-left: $paddingLeft;
  }

  &__addons {
    margin-top: 40px;
  }

  .hello-link {
    margin-bottom: 4px;
    border-radius: $border-radius;

    a {
      color: $primary;
      transition: none;
      padding: $paddingLeft;
      display: block;
    }

    &:hover {
      background: $secondary-color;
    }
  }

  button.add-workspace {
    margin: 0 0 0 5px;
    font-size: 1.1em;
  }

  .icon {
    margin-right: 10px;
  }
}