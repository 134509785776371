.calendar-weekdays {
  input {
    display: none;

    &:checked ~ span {
      background: #1765cc;
      color: #fff;
    }
  }

  label {
    margin-right: 5px;
  }

  span {
    cursor: pointer;
    background: #eeeeee;
    color: #616161;
    font-size: 0.8em;
    padding: 6px 10px;
    border-radius: 16px;
  }
}