$scrollbarWidth: 12px;

.grabbing {
  user-select: none;
}

.tl {
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  .exp {
    width: 20px;
    height: 20px;
    background: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='19.2' height='19.2' viewBox='0 0 16 16'%3E%3Cpath fill='currentColor' d='M4.802 6h6.396c.66 0 1.036.77.64 1.309L8.64 11.672a.788.788 0 01-1.279 0l-3.2-4.363C3.766 6.769 4.142 6 4.801 6'/%3E%3C/svg%3E") no-repeat 50%;
    outline: none;
    border: 0;
    cursor: pointer;
    margin-right: 6px;
    transition: transform .1s ease-out
  }

  &-grid {
    cursor: grab;
  }

  &.grabbing {
    .tl-grid {
      cursor: grabbing;
    }
  }

  &.no-split {
    overflow: auto hidden;

    .tl-header {
      overflow: hidden;
      margin-right: $scrollbarWidth;

      &-pr {
        flex: 1 0 auto;
      }

      &-pr-scroller {
        overflow: visible;
      }
    }

    .tl-body {
      display: flex;
      overflow: auto;
    }

    .tl-resources {
      flex: 1 0 auto;
    }

    .tl-canvas {
      flex: 1 0 auto;
    }

    .tl-h-scroll {
      display: none;
    }

    .tl-cells {
      position: absolute;
      width: 100%;
      top: 0;
      left: 0;
    }
  }

  &-header {
    display: flex;
    flex: 0 0 auto;
    border-bottom: 1px solid #d1d3d4;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.07);

    &-columns {
      display: flex;
    }

    .tl-column {
      width: 60px;
      display: flex;
      align-items: center;
      padding: 12px 5px;
    }

    &-pr {
      flex: 1 1;
      margin-right: $scrollbarWidth;
    }

    &-pr-row {
      display: flex;
      position: relative;
    }

    &-pr-cell {
      padding: 6px 0;
      text-align: center;
      flex: 0 0;
    }

    &-pr-scroller {
      overflow: hidden;
    }
  }

  &-body {
    flex-grow: 1;
    overflow: hidden auto;
    contain: strict;
  }

  &-clip {
    display: flex;
  }

  &-grid {
    flex: 1 1 0;
    overflow: auto hidden;
    position: relative;
    overscroll-behavior: contain auto;

    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  &-resources {
    height: 100%;
    overscroll-behavior: auto contain;
    display: flex;

    &-columns {
      flex: 1 1 auto;
    }

    .tl-group {
      display: flex;
      align-items: center;
      padding: 0 5px;
    }
  }

  &-group {
    cursor: pointer;
    z-index: 3;
    background: #fff;
    position: relative;
    box-sizing: border-box;
    border-bottom: 1px solid #e5e5e5;

    &.collapsed {
      .exp {
        transform: rotate(-90deg);
      }
    }
  }

  &-resource {
    display: flex;
    align-items: center;
    padding: 0 5px;
    border-bottom: 1px solid #e5e5e5;
    box-sizing: border-box;
  }

  &-row {
    border-bottom: 1px solid #e5e5e5;
    width: 100%;
    box-sizing: border-box;
    position: relative;

    &-h {
      width: 100%;
      height: 100%;
    }
  }

  &-row-hover {
    background: #f9f9f9;
  }

  &-canvas {
    position: relative;
  }

  &-cells {
    position: absolute;
    pointer-events: none;
    z-index: 1;
  }

  &-cell {
    position: absolute;
    height: 100%;
    border-right: 1px solid #f0f0f0;
    top: 0;
    box-sizing: border-box;
  }

  &-events {
    position: absolute;
    top: 0;
    left: 0;
  }

  &-event {
    box-sizing: border-box;
    position: absolute;
    will-change: contents, width, height, transform;
    transform-style: flat;
    z-index: 5;
    pointer-events: all;
    transition: background-color 0.2s, color 0.2s, opacity 0.2s, font-weight 0.2s, border 0.2s;
    contain: layout style;
    cursor: pointer;
    height: 100%;

    &-content {
      background: #3b7dec;
      color: #fff;
      height: 100%;
      display: flex;
      align-items: center;
      padding: 0 7px 0 10px;
  
      > span {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        position: -webkit-sticky;
        position: sticky;
        left: 10px;
      }
    }
  }

  &-h-scroll {
    flex: 0 0 $scrollbarWidth;
    overflow: auto hidden;
    contain: strict;
    margin-right: $scrollbarWidth;

    &-gag {
      height: 1px;
    }
  }

  .spl {
    width: 3px;
    height: 100%;
    background: rgb(237, 237, 237);
    cursor: col-resize;
    flex-shrink: 0;
  }


  ::-webkit-scrollbar {
    width: $scrollbarWidth;
    height: $scrollbarWidth;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #c2c2c2;
    border-radius: 10px;
    background-clip: content-box
  }

  ::-webkit-scrollbar-thumb:hover {
    background-color: #7d7d7d;
    border-radius: 10px;
    background-clip: content-box
  }

  ::-webkit-scrollbar-thumb:vertical,::-webkit-scrollbar-thumb:vertical:hover {
    border: 3px solid transparent;
    border-left-width: 4px
  }

  ::-webkit-scrollbar-thumb:horizontal,::-webkit-scrollbar-thumb:horizontal:hover {
    border: 3px solid transparent;
    border-top: 4px solid transparent
  }

  ::-webkit-scrollbar-track {
    background-color: #fafafa;
  }

  ::-webkit-scrollbar-track:vertical {
    border-left: 1px solid #e8e8e8
  }

  ::-webkit-scrollbar-track:horizontal {
    border-top: 1px solid #e8e8e8
  }

  ::-webkit-scrollbar-corner {
    background-color: #fafafa
  }
}
