.hello-topbar {
  > div {
    display: flex;
    width: 100%;
    align-items: center;
  }

  .logo {
    height: 40px;
  }

  background: #fff;
  width: 100%;
  box-shadow: rgba(0, 0, 0, 0.03) 0px 2px 0px 0px;
  display: flex;
  justify-content: center;
  padding: 1em 35px;
  box-sizing: border-box;

  &__nav {
    padding: 0 3em;
    flex-grow: 1;
    display: flex;
    align-items: center;
    font-size: 1.4em;

    &-item {
      &.active {
        border-bottom: 2px solid #333333;
        opacity: 1;
      }

      color: #333333;
      margin: 0 1em;
      padding: 3px 0;
      opacity: .7;
      text-decoration: none;
      transition: all .2s;

      &:hover {
        opacity: 1;
      }
    }
  }
}
