.e-checkbox {
  display: inline-flex;
  align-items: center;
  position: relative;

  &:hover {
    .e-inner {
      border-color: var(--irv-border);
    }
  }

  > input {
    position: absolute;
    opacity: .00001;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    margin: 0;

    &:focus + .e-outer {
      .e-inner {
        border-color: var(--irv-border-focus);
      }
    }

    &:checked + .e-outer {
      .e-inner {
        background-color: var(--irv-btn-primary);
        border-color: var(--irv-border-focus);
      }
    }
  }

  .e-outer {
    width: 22px;
    height: 22px;
    box-sizing: border-box;
    border-radius: 6px;
    position: relative;
    cursor: pointer;
    display: inline-block;
    overflow: hidden;
    padding: 2px;
  }

  .e-inner {
    border: 2px solid #cdcdcd;
    border-radius: 4px;
    display: block;
    box-sizing: border-box;
    height: 100%;
    width: 100%;

    svg {
      width: 10px;
      height: 10px;
      fill: #fff;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  &-inner {
    width: 20px;
    height: 20px;
    box-sizing: border-box;
    padding: 2px;
    background-color: #fff;
    transition: border-color 0.3s;
    position: relative;
    cursor: pointer;
    display: inline-block;
    border-radius: 4px;

    &:before {
      content: "";
      position: absolute;
      display: block;
      border: 2px solid #cdcdcd;
      border-radius: 4px;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }
  }

  > input {
    position: absolute;
    opacity: .00001;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    margin: 0;

    &.e-ring + .e-checkbox-inner {
      box-shadow: 0 0 0 1px #2680eb;
      border-radius: 6px;
    }

    &:focus ~ .e-checkbox-inner {
      &:before {
        border-color: #38a0ff;
      }
    }

    &:checked ~ .e-checkbox-inner {
      background-color: #2490f5;

      &:before {
        border-color: #2490f5;
      }

      &:after {
        content: "";
        position: absolute;
        display: block;
        transform: rotate(45deg) scale(1);
        border: 2px solid #fff;
        border-top: 0;
        border-left: 0;
        transition: opacity .2s;
        opacity: 1;
        left: 7.1px;
        top: 2.2px;
        width: 4px;
        height: 10px;
      }
    }
  }
}
