.eyzy-switch {
  margin: 0;
  padding: 0;
  color: #333;
  position: relative;
  display: inline-block;
  outline: none;
  box-sizing: border-box;
  min-width: 40px;
  height: 22px;
  line-height: 20px;
  background-color: #0052cc;
  border: 1px solid transparent;
  border-radius: 100px;
  cursor: pointer;
  transition: all 0.2s;
  user-select: none;

  &-container {
    display: inline-flex;
    align-items: center;
  }

  &-text {
    padding-left: 5px;
    cursor: pointer;
  }

  &::after {
    position: absolute;
    top: 1px;
    left: 100%;
    margin-left: -19px;
    width: 18px;
    height: 18px;
    background-color: #fff;
    border-radius: 18px;
    cursor: pointer;
    transition: all 0.2s cubic-bezier(0.78, 0.14, 0.15, 0.86);
    content: ' ';
  }

  &-sm {
    min-width: 24px;
    height: 16px;
    line-height: 14px;

    &::after {
      width: 12px;
      height: 12px;
      margin-left: -13px;
    }
  }
}

.eyzy-switch[aria-checked=false] {
  background: #b9b9b9;

  &::after {
    left: 1px;
    margin-left: 0;
  }
}
