@import "../variables";

.c-custom {
  height: 100%;
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;

  &-wrap {
    display: flex;
    overflow: hidden;
    width: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
  }

  &-s {
    overflow: auto;
    display: flex;
    flex: 1 1 auto;
    position: relative;
  }

  &.c-t {
    .c-custom-events {
      overflow: hidden;
    }

    .c-custom-s {
      overflow: hidden;
    }

    .c-custom-tf {
      position: absolute;
      left: 40px;
      top: 0;
      right: 0;
      bottom: 0;
      overflow: auto;
      touch-action: pan-x pan-y;
      z-index: 999;
    }
  }

  &-time {
    flex: 0 0 40px;
    overflow: hidden;

    div {
      position: relative;

      span {
        position: absolute;
        top: -7px;
        left: 7px;
        color: #636e78;
        font-size: 10px;
      }
    }

    .dt > div:first-child {
      visibility: hidden;
    }
  }

  .c-hcell {
    flex-direction: row;
    align-items: center;

    .c-text {
      margin-left: 3px;
    }
  }

  &-events {
    display: flex;
    flex: 1;
    border-left: 1px solid $mainBorderColor;
    position: relative;
    overflow: auto;

    .dt {
      position: absolute;
      width: 100%;

      > div {
        &:before {
          border-bottom: #dadce0 1px solid;
        }
      }
    }
  }

  .c-event {
    width: 100%;
    position: absolute;
    padding: 0 0 0 5px;
  }

  .c-cell {
    border-right: 1px solid $mainBorderColor;
    position: relative;
    padding-right: 6px;
    overflow: visible;

    &:last-child {
      border-color: transparent;
    }
  }

  ::-webkit-scrollbar {
    background: transparent;
    height: 8px;
    width: 8px;
  }

  ::-webkit-scrollbar-thumb {
    border: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    background: #dadce0;
    -webkit-border-radius: 8px;
    border-radius: 8px;
    min-height: 40px;
  }

  :hover::-webkit-scrollbar-thumb {
    background: #bdc1c6;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #bdc1c6;
  }

  ::-webkit-scrollbar-thumb:active {
    background: #80868b;
  }
}
