@import '../../../../../assets/style/variables';

.dt-selector {
  display: flex;

  input {
    outline: none;
  }
}

.date-range {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;

  .ts {
    input {
      max-width: 56px;
      height: 34px;
    }

    &-e {
      color: $gray-color;
      padding-left: 15px;
    }

    &-list {
      height: 200px;
      overflow: auto;

      li {
        height: 35px;
        padding: 0 20px;
        white-space: nowrap;
        line-height: 35px;

        &:hover {
          cursor: pointer;
          background-color: #f0f0f0;
        }

        &.active {
          background-color: #216ba5;
          color: white;
          font-weight: bold;
        }
      }
    }

    .ant-tooltip-arrow {
      display: none;
    }

    .ant-tooltip-inner {
      padding: 0;
      background: #fff;
      color: #000c17;
    }
  }

  input {
    border: 0;
    outline: none;
    background: #f0f3f4;
    padding: 8px;
    border-radius: 4px;
    font-size: 12px;
  }

  .react-datepicker-wrapper {
    margin-right: 5px;
  }

  .column-def {
    margin-bottom: 7px;
    color: #9f9f9f;
  }

  &-ctrl {
    display: flex;
    flex-wrap: nowrap;

    > .react-datepicker-wrapper {
      width: 60px;
    }
  }

  &__time {
    max-width: 56px;
  }

  &__interval {
    display: flex;
    flex: 1 1 0;
    flex-direction: column;
    overflow: hidden;

    &.end {
      margin-left: 10px;

      .ts {
        margin-right: 5px;
      }
    }
  }
}

@media screen and (max-width: 500px) {
  .date-range {
    flex-direction: column;
    align-items: flex-start;

    .separator {
      display: none;
    }

    &__date {
      max-width: initial;
      width: 100%;
    }

    &__interval {
      margin-bottom: 10px;
      width: 100%;

      .date {
        flex: 1 1 auto;
        margin-right: 5px;

        .react-datepicker-wrapper {
          width: 100%;
        }
      }

      &.end {
        margin: 10px 0;

        .date-range-ctrl {
          flex-direction: row-reverse;
        }

        .ts {
          margin: 0;
        }
      }
    }
  }
}