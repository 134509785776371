@import "../../../assets/style/variables";

.list-widget {
  &-name {
    display: flex;
    flex-grow: 1;

    .name {
      border-bottom: 1px dashed transparent;
      white-space: nowrap;
    }

    .badge {
      flex-grow: 1;
      display: flex;
      margin: 0 10px;
      max-width: 130px;
    }

    .count {
      color: $gray-color;
      margin-left: auto;
    }
  }
}

.list-container {
  margin: 30px 0;

  .irv-list {
    .eyzy-tag__remove-btn {
      display: none;
    }

    .nb {
      height: 100%;
    }
  }

  .linked-records {
    overflow-x: auto;
    overflow-y: hidden;

    &__row {
      height: auto;
      border: 0;
      margin: 0;

      .body {
        overflow: initial;
      }
    }
  }

  .col-name {
    display: none;
  }

  .no-data {
    padding: 0 40px;
  }

  .ant-collapse {
    &:hover {
      .list-widget-name .name {
        border-color: inherit;
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .list-container {
    .record {
      background: #ffffff;
    }

    .no-data {
      padding: 0 15px;
    }

    .ant-collapse-header {
      font-weight: 500;
    }

    svg {
      color: $mainExtraElementColor;
      font-size: 15px;
    }
  }
}
