$b0: #8c0404;
$b1: #e6304f;
$b2: #ff9900;
$b3: #1ac12c;
$b4: #00a7a7;
$b5: #50b2ee;
$b6: #216bff;
$b7: #9107ee;
$b8: #eb06eb;
$b9: #5e5e5e;
$b10: #0a0a0a;

:root {
  --irv-color-b0: #8c0404;
  --irv-color-b1: #e6304f;
  --irv-color-b2: #ff9900;
  --irv-color-b3: #1ac12c;
  --irv-color-b4: #00a7a7;
  --irv-color-b5: #50b2ee;
  --irv-color-b6: #216bff;
  --irv-color-b7: #9107ee;
  --irv-color-b8: #eb06eb;
  --irv-color-b9: #5e5e5e;
  --irv-color-b10: #0a0a0a;

  --irv-color-c0: #cc4124;
  --irv-color-c1: #e06666;
  --irv-color-c2: #f6b26b;
  --irv-color-c3: #93c47d;
  --irv-color-c4: #76a5af;
  --irv-color-c5: #92cff4;
  --irv-color-c6: #6d9eeb;
  --irv-color-c7: #8e7cc3;
  --irv-color-c8: #c27ba0;
  --irv-color-c9: #c6c6c6;
  --irv-color-c10: #a7a7a7;

  --irv-color-d0: #e3b6ad;
  --irv-color-d1: #f8cece;
  --irv-color-d2: #f6dfc7;
  --irv-color-d3: #d7e8d0;
  --irv-color-d4: #cdebeb;
  --irv-color-d5: #c6e6f9;
  --irv-color-d6: #d2e5f7;
  --irv-color-d7: #dad2eb;
  --irv-color-d8: #efd5e0;
  --irv-color-d9: #e8e8e8;
  --irv-color-d10: #f3f3f3;
}

.color {
  &-b {
    &0 {
      background: $b0;
      color: #fff;
    }

    &1 {
      background: $b1;
      color: #fff;
    }

    &2 {
      background: $b2;
    }

    &3 {
      background: $b3;
      color: #fff;
    }

    &4 {
      background: $b4;
    }

    &5 {
      background: $b5;
    }

    &6 {
      background: $b6;
      color: #fff;
    }

    &7 {
      background: $b7;
      color: #fff;
    }

    &8 {
      background: $b8;
      color: #fff;
    }

    &9 {
      background: $b9;
      color: #fff;
    }

    &10 {
      background: $b10;
      color: #fff;
    }
  }

  &-c {
    &0 {
      background: #cc4124;
    }

    &1 {
      background: #e06666;
    }

    &2 {
      background: #f6b26b;
    }

    &3 {
      background: #93c47d;
    }

    &4 {
      background: #76a5af;
    }

    &5 {
      background: #92cff4;
    }

    &6 {
      background: #6d9eeb;
    }

    &7 {
      background: #8e7cc3;
    }

    &8 {
      background: #c27ba0;
    }

    &9 {
      background: #c6c6c6;
    }

    &10 {
      background: #c6c6c6;
    }
  }

  &-d {
    &0 {
      background: #e3b6ad;
    }

    &1 {
      background: #f8cece;
    }

    &2 {
      background: #f6dfc7;
    }

    &3 {
      background: #d7e8d0;
    }

    &4 {
      background: #cdebeb;
    }

    &5 {
      background: #c6e6f9;
    }

    &6 {
      background: #d2e5f7;
    }

    &7 {
      background: #dad2eb;
    }

    &8 {
      background: #efd5e0;
    }

    &9 {
      background: #e8e8e8;
    }

    &10 {
      background: #e8e8e8;
    }
  }
}

// кнопки с цветом
.c-btn {
  color: #fff;

  &.color {
    &-b {
      &0 {
        &:hover {
          background: lighten($b0, 5%);
        }

        &:active {
          background: darken($b0, 5%);
        }
      }

      &1 {
        &:hover {
          background: lighten($b1, 5%);
        }

        &:active {
          background: darken($b1, 5%);
        }
      }

      &2 {
        &:hover {
          background: lighten($b2, 5%);
        }

        &:active {
          background: darken($b2, 5%);
        }
      }

      &3 {
        &:hover {
          background: lighten($b3, 5%);
        }

        &:active {
          background: darken($b3, 5%);
        }
      }

      &4 {
        &:hover {
          background: lighten($b4, 5%);
        }

        &:active {
          background: darken($b4, 5%);
        }
      }

      &5 {
        &:hover {
          background: lighten($b5, 5%);
        }

        &:active {
          background: darken($b5, 5%);
        }
      }

      &6 {
        &:hover {
          background: lighten($b6, 5%);
        }

        &:active {
          background: darken($b6, 5%);
        }
      }

      &7 {
        &:hover {
          background: lighten($b7, 5%);
        }

        &:active {
          background: darken($b7, 5%);
        }
      }

      &8 {
        &:hover {
          background: lighten($b8, 5%);
        }

        &:active {
          background: darken($b8, 5%);
        }
      }

      &9 {
        &:hover {
          background: lighten($b9, 5%);
        }

        &:active {
          background: darken($b9, 5%);
        }
      }
    }
  }
}

.irv-row {
  &.ic {
    .irv-cell.active {
      .irv-spc {
        box-shadow: 0 0 0 3px #fff;
      }

      input, textarea {
        background: inherit;
      }
    }

    .sl, .us {
      .eyzy-tag {
        border: 1px solid #fff;
      }
    }

    .fr, .sl {
      .eyzy-tag {
        color: #343434;
      }
    }

    &.b {
      @for $i from 0 to 11 {
        &#{$i} {
          .irv-cell {
            background: var(--irv-color-b#{$i});
            color: #fff;
          }
        }
      }
    }

    &.c {
      @for $i from 0 to 11 {
        &#{$i} {
          .irv-cell {
            background: var(--irv-color-c#{$i});
            color: #fff;
          }
        }
      }
    }


    &.d {
      @for $i from 0 to 11 {
        &#{$i} {
          .irv-cell {
            background: var(--irv-color-d#{$i});
            color: #fff;
          }
        }
      }
    }
  }

}

.gc-row {
  .grid-row.c {
    &:before {
      content: '';
      display: block;
      width: 100%;
      height: 100%;
      user-select: none;
      opacity: .2;
      pointer-events: none;
      position: absolute;
    }

    &.b {
      &0 {
        &:before {
          background: $b0;
        }
      }

      &1 {
        &:before {
          background: $b1;
        }
      }

      &2 {
        &:before {
          background: $b2;
        }
      }

      &3 {
        &:before {
          background: $b3;
        }
      }

      &4 {
        &:before {
          background: $b4;
        }
      }

      &5 {
        &:before {
          background: $b5;
        }
      }

      &6 {
        &:before {
          background: $b6;
        }
      }

      &7 {
        &:before {
          background: $b7;
        }
      }

      &8 {
        &:before {
          background: $b8;
        }
      }

      &9 {
        &:before {
          background: $b9;
        }
      }

      &10 {
        &:before {
          background: $b10;
        }
      }
    }
  }
}
