.irv {
  &-grid {
    height: 100%;
    width: 100%;
    overflow: hidden;
    position: relative;
    cursor: default;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    isolation: isolate;

    //::-webkit-scrollbar {
    //  width: $scrollBarWidth;
    //  height: $scrollBarWidth;
    //}

    ::-webkit-scrollbar-thumb {
      background-color: #c2c2c2;
      border-radius: 10px;
      background-clip: content-box
    }

    ::-webkit-scrollbar-thumb:hover {
      background-color: #7d7d7d;
      border-radius: 10px;
      background-clip: content-box
    }

    ::-webkit-scrollbar-thumb:vertical, ::-webkit-scrollbar-thumb:vertical:hover {
      border: 3px solid transparent;
      border-left-width: 4px
    }

    ::-webkit-scrollbar-thumb:horizontal, ::-webkit-scrollbar-thumb:horizontal:hover {
      border: 3px solid transparent;
      border-top: 4px solid transparent
    }

    ::-webkit-scrollbar-track {
      background-color: #fff;
    }

    ::-webkit-scrollbar-track:vertical {
      border-left: 1px solid #e8e8e8
    }

    ::-webkit-scrollbar-track:horizontal {
      border-top: 1px solid #e8e8e8
    }

    ::-webkit-scrollbar-corner {
      background-color: #fff
    }
  }

  &-body {
    position: relative;
  }

  &-row {
    &:first-child {
      .active {
        &:before {
          top: 0;
        }
      }
    }
  }

  &-row, &-rwrap {
    display: flex;
  }

  &-cell {
    border-right: 1px solid var(--irv-border-c);
    border-bottom: 1px solid var(--irv-border-r);
    box-sizing: border-box;
    background: #fff;
    z-index: 3;
    position: relative;
    flex: 1 1 auto;

    &.active {
      z-index: 4;

      //&:before {
      //  content: '';
      //  position: absolute;
      //  box-sizing: border-box;
      //  top: -2px;
      //  right: -2px;
      //  bottom: -2px;
      //  left: -2px;
      //  display: block;
      //  border: 2px solid #2d7ff9;
      //  pointer-events: none;
      //  border-radius: 1px;
      //}
    }
  }

  &-frz {
    position: sticky;
    left: 0;
    display: flex;
    flex: 0 0 auto;
    z-index: 4;
  }

  &-caret {
    background: transparent;
    border: 0;
    outline: none;
    display: inline-flex;
    flex: 0 0 45px;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    opacity: .7;

    svg {
      width: 20px;
      height: 20px;
      flex: 0 0 auto;
      transition: transform .1s ease-out;
    }

    &:hover {
      opacity: 1;
    }
  }

  &-grid > div {
    &::-webkit-scrollbar {
      width: $scrollBarWidth;
      height: $scrollBarWidth;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #c2c2c2;
      border-radius: 10px;
      background-clip: content-box;
    }

    ::-webkit-scrollbar-thumb:hover {
      background-color: #7d7d7d;
      border-radius: 10px;
      background-clip: content-box
    }

    ::-webkit-scrollbar-thumb:vertical, ::-webkit-scrollbar-thumb:vertical:hover {
      border: 3px solid transparent;
      border-left-width: 4px
    }

    ::-webkit-scrollbar-thumb:horizontal, ::-webkit-scrollbar-thumb:horizontal:hover {
      border: 3px solid transparent;
      border-top: 4px solid transparent
    }

    ::-webkit-scrollbar-track {
      background-color: #fafafa;
    }

    ::-webkit-scrollbar-track:vertical {
      border-left: 1px solid #e8e8e8
    }

    ::-webkit-scrollbar-track:horizontal {
      border-top: 1px solid #e8e8e8
    }

    ::-webkit-scrollbar-corner {
      background-color: #fafafa
    }
  }
}


.i-grid {
  height: 100%;
  width: 100%;
  overflow: hidden;
  position: relative;
  cursor: default;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;

  &-column {
    display: flex;
    padding: 0 5px;
    align-items: center;
    border-right: 1px solid $headerBorderColor;
    box-sizing: border-box;
  }

  &-cell {
    border-right: 1px solid $cellBorderColor;
    border-bottom: 1px solid $cellBorderColor;
    padding: 0 6px;
    box-sizing: border-box;
    display: flex;
    align-items: center;

    &.active {
      &:before {
        content: '';
        position: absolute;
        box-sizing: border-box;
        top: -1px;
        right: 0;
        bottom: -1px;
        left: 0;
        display: block;
        border: 2px solid #2d7ff9;
        pointer-events: none;
      }
    }
  }

  &-fh {
    display: flex;
  }

  &-mv {
    scrollbar-width: none;
    -ms-overflow-style: none;

    &::-webkit-scrollbar {
      display: none;
    }

    > div {
      margin-bottom: $scrollBarWidth;
    }
  }

  &-fv {
    > div {
      margin-bottom: $scrollBarWidth;
    }
  }

  .fv {
    position: absolute;
    bottom: 0;
    left: 0;
    height: $scrollBarWidth;
    border-right: 1px solid #e8e8e8;
    border-top: 1px solid #e8e8e8;
    background: #fafafa;
  }

  &.scrolled {
    .i-grid-fv, .i-grid-fh {
      box-shadow: 0 0 5px #bdbbbb;
      clip-path: inset(0px -5px 0 0);
    }

    .i-grid-fh {
      .i-grid-column {
        &:last-child {
          border-right: 1px solid $cellBorderColor;
        }
      }
    }
  }
}
