$base-color-link: #434343;

.base-link {
  color: $base-color-link;
  display: flex;
  align-items: center;
  font-weight: 600;

  .icon {
    width: 25px;
    height: 25px;
    flex: 0 0 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    margin-right: 8px;
    color: #fff;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.16);

    &.b {
      color: #222222;
    }
  }
}
