@font-face {
  font-family: 'iorvo';
  src:
    url('fonts/iorvo.ttf?txijqm') format('truetype'),
    url('fonts/iorvo.woff?txijqm') format('woff'),
    url('fonts/iorvo.svg?txijqm#iorvo') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="i-"], [class*=" i-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'iorvo' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.i-mirror:before {
  content: "\e94c";
}
.i-external-2:before {
  content: "\e94b";
}
.i-external:before {
  content: "\e94a";
}
.i-r-share:before {
  content: "\e949";
}
.i-b-cross-circle:before {
  content: "\e948";
}
.i-network:before {
  content: "\e947";
}
.i-expand:before {
  content: "\e946";
}
.i-help:before {
  content: "\e945";
}
.i-right-left:before {
  content: "\e93d";
}
.i-count:before {
  content: "\e93f";
}
.i-double-tap:before {
  content: "\e940";
}
.i-l-count:before {
  content: "\e941";
}
.i-code:before {
  content: "\e942";
}
.i-t-formula:before {
  content: "\e943";
}
.i-search:before {
  content: "\e944";
}
.i-fx:before {
  content: "\e93e";
}
.i-grouping:before {
  content: "\e936";
}
.i-color:before {
  content: "\e937";
}
.i-sort:before {
  content: "\e938";
}
.i-up-arrow:before {
  content: "\e939";
}
.i-down-arrow:before {
  content: "\e93a";
}
.i-arrow-left:before {
  content: "\e93b";
}
.i-arrow-right:before {
  content: "\e93c";
}
.i-eye:before {
  content: "\e934";
}
.i-c-eye:before {
  content: "\e935";
}
.i-short:before {
  content: "\e930";
}
.i-medium:before {
  content: "\e931";
}
.i-tall:before {
  content: "\e932";
}
.i-extra-tall:before {
  content: "\e933";
}
.i-cross-circle:before {
  content: "\e92e";
}
.i-cross-small:before {
  content: "\e92f";
}
.i-pencil:before {
  content: "\e92c";
}
.i-edit:before {
  content: "\e92d";
}
.i-drag:before {
  content: "\e926";
}
.i-star:before {
  content: "\e924";
}
.i-filter:before {
  content: "\e925";
}
.i-f-apps:before {
  content: "\e92b";
}
.i-transfer:before {
  content: "\e927";
}
.i-duplicate:before {
  content: "\e928";
}
.i-dislike:before {
  content: "\e929";
}
.i-f-star:before {
  content: "\e92a";
  color: #ffc107;
}
.i-d-calendar:before {
  content: "\e919";
}
.i-checkbox:before {
  content: "\e91a";
}
.i-phone:before {
  content: "\e91b";
}
.i-at:before {
  content: "\e91c";
}
.i-link:before {
  content: "\e91d";
}
.i-one:before {
  content: "\e91e";
}
.i-user:before {
  content: "\e91f";
}
.i-users-alt:before {
  content: "\e920";
}
.i-list:before {
  content: "\e921";
}
.i-long:before {
  content: "\e922";
}
.i-text:before {
  content: "\e923";
}
.i-more:before {
  content: "\e900";
}
.i-dots:before {
  content: "\e901";
}
.i-table:before {
  content: "\e914";
}
.i-calendar:before {
  content: "\e915";
}
.i-kanban:before {
  content: "\e916";
}
.i-dashboard:before {
  content: "\e917";
}
.i-bell:before {
  content: "\e918";
}
.i-shield:before {
  content: "\e913";
}
.i-caret-circle-down:before {
  content: "\e902";
}
.i-drop:before {
  content: "\e903";
}
.i-b-lock:before {
  content: "\e904";
}
.i-lock:before {
  content: "\e905";
}
.i-trash:before {
  content: "\e906";
}
.i-b-trash:before {
  content: "\e907";
}
.i-share:before {
  content: "\e908";
}
.i-settings:before {
  content: "\e909";
}
.i-settings-sliders:before {
  content: "\e90a";
}
.i-plus:before {
  content: "\e90b";
}
.i-add-circle:before {
  content: "\e90c";
}
.i-b-add-circle:before {
  content: "\e90d";
}
.i-b-caret-circle-down:before {
  content: "\e90e";
}
.i-caret-right:before {
  content: "\e90f";
}
.i-caret-down:before {
  content: "\e910";
}
.i-double-small-right:before {
  content: "\e911";
}
.i-double-small-left:before {
  content: "\e912";
}
