.dt {
  > div {
    height: 50px;

    &:before {
      content: '';
      left: 0;
      position: absolute;
      width: 100%;
      margin-top: -1px;
      z-index: 3;
      pointer-events: none;
    }
  }
}

.ct {
  position: absolute;
  width: 100%;
  height: 2px;
  background-color: #ff3c3c;
  user-select: none;

  &::before {
    content: '';
    display: block;
    width: 8px;
    height: 8px;
    position: absolute;
    left: -4px;
    top: 1px;
    background: #ff3c3c;
    border-radius: 50%;
    transform: translateY(-50%);
  }
}
