.eyzy-dropdown {
  .icon {
    font-size: 1.4em;
    margin-right: 10px;
  }

  ul.ant-dropdown-menu-dark {
    background-color: hsl(0, 0%, 20%);

    & li {
      color: #fff;
      display: flex;
      align-items: center;
    }

    & li:hover {
      background-color: hsl(0, 0%, 40%);
    }
  }
}