.irv-tray {
  display: flex;
  flex-direction: column;
  background: #fff;
  width: 100%;
  transition: transform 0.25s cubic-bezier(0,0,0.4,1), opacity 0.25s cubic-bezier(0,0,0.4,1);
  opacity: 0;
  pointer-events: none;
  visibility: hidden;
  background-clip: padding-box;
  border: 0;
  border-radius: 20px 20px 0 0;
  overflow: hidden;
  box-shadow: 0 3px 6px -4px rgb(0 0 0 / 12%), 0 6px 16px 0 rgb(0 0 0 / 8%), 0 9px 28px 8px rgb(0 0 0 / 5%);
  transform: translateY(100%);
  position: absolute;
  bottom: 0;
  max-height: 50%;
  z-index: 1000;

  &.open {
    visibility: visible;
    opacity: 1;
    pointer-events: auto;
    transform: translateY(0);
    transition-delay: 0s;
  }
}
