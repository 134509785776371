.cm {
  &-header {
    padding: 10px 5px;
    border-bottom: 1px solid #eee;

    &-title {
      padding: 0 5px 7px 5px;
      text-transform: uppercase;
      font-size: 0.8em;
      color: #727e8f;
      font-weight: 600;
    }

    .din {
      width: 240px;
      margin: 0 5px;
    }
  }

  &-mheader {
    padding: 5px 7px;
    border-bottom: 1px solid #eee;

    ul.ant-menu.ant-menu-vertical {
      padding: 0;
      border: 0;
      margin-top: 5px;
    }
  }

  &-dpd-wrap {
    margin-top: 10px;
  }

  &-base {
    &-exterior {
      width: 260px;
      background: #fff;
      box-sizing: border-box;
      padding: 8px;
      border-radius: 4px;
      border: 1px solid #dedada;
    }
  }

  .exterior {
    &-colors {
      margin-top: 10px;
      height: 75px;
      display: flex;
      overflow: auto;
      flex-wrap: wrap;
      justify-content: space-around;

      span {
        margin: 1px 1px;
        height: 23px;
        flex: 1 1 9%;
        border: 1px solid #f1efef;

        &.active:before {
          content: '';
          display: block;
          width: 100%;
          height: 100%;
          background: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNnB4IiBoZWlnaHQ9IjE2cHgiIHZpZXdCb3g9IjAgMCA0OCA0OCIgZmlsbD0iI0ZGRkZGRiI+PHBhdGggZD0iTTAgMGg0OHY0OEgweiIgZmlsbD0ibm9uZSIvPjxwYXRoIGQ9Ik0xOCAzMi4zNEw5LjY2IDI0bC0yLjgzIDIuODNMMTggMzhsMjQtMjQtMi44My0yLjgzeiIvPjwvc3ZnPg==') center no-repeat;
        }

        &.active.b:before {
          background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNnB4IiBoZWlnaHQ9IjE2cHgiIHZpZXdCb3g9IjAgMCA0OCA0OCIgZmlsbD0iIzAwMDAwMCI+PGcgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMSwgMCwgMCwgMSkiPjxwYXRoIGQ9Ik0wIDBoNDh2NDhIMHoiIGZpbGw9Im5vbmUiLz48cGF0aCBkPSJNMTggMzIuMzRMOS42NiAyNGwtMi44MyAyLjgzTDE4IDM4bDI0LTI0LTIuODMtMi44M3oiLz48L2c+PC9zdmc+');
        }

        &:hover {
          box-shadow: 0 0 2px rgba(0, 0, 0, 0.5);
        }
      }
    }

    &-icons {
      margin-top: 10px;
      padding: 10px 0;
      display: flex;
      flex-wrap: wrap;
      height: 115px;
      overflow: auto;

      > span {
        padding: 6px 3px;
        cursor: pointer;
        flex: 0 0 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 30px;

        &:hover {
          background: #e8e6e6;
        }
      }
    }
  }

  button.cm-dpd {
    justify-content: flex-start;
    width: 100%;
    border-radius: 6px;
    height: 32px;
    padding: 0 10px;

    &:hover {
      color: rgba(0, 0, 0, 0.85);
      background-color: #eee;
    }

    span {
      margin-right: auto;
      padding-left: 10px;
    }
  }

  &.ant-tooltip {
    max-width: unset;
  }

  .ant-tooltip {
    &-arrow {
      display: none;
    }

    &-inner {
      background: #fff;
      padding: 0;
      color: #414141;
      border-radius: 6px;
    }
  }

  .ant-menu.ant-menu-vertical {
    padding: 5px 7px;
    border-radius: 6px;
    border: 0;

    .ant-menu {
      &-item, &-item:not(:last-child) {
        margin: 0;
        height: 32px;
        line-height: 32px;
        border-radius: 6px;
        padding: 0 10px;
      }

      &-item {
        &:hover {
          color: rgba(0, 0, 0, 0.85);
          background-color: #eee;
        }

        :hover {
        }
      }
    }
  }

  .rm {
    color: #c71e14;
  }
}

@media (max-width: 768px) {
  .cm {
    &-header {
      .din {
        width: 100%;
        height: 40px;
      }
    }

    .ant-menu.ant-menu-vertical {
      .ant-menu {
        &-item, &-item:not(:last-child) {
          height: 42px;
          line-height: 42px;
        }
      }
    }
  }
}
