html, body {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  min-height: -moz-available;
  min-height: -webkit-fill-available;
  min-height: fill-available;
}

body {
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: hidden;
  line-height: 18px;
  font-size: 13px;
  color: hsl(0, 0%, 30%);
  font-family: -apple-system, system-ui, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen-Sans, sans-serif, 'Apple Color Emoji';
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

#root {
  width: 100%;
  height: 100%;
}
