@import "../variables";

.calendar {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
}

.c {
  overflow: hidden;

  .render-range {
    font-weight: bold;
    font-size: 1.2em;
    line-height: 1;
    margin-left: 10px;
    text-transform: capitalize;
    flex: 1;
    text-align: left;
  }

  .today {
    .c-cell-text {
      color: #fff;
      background-color: #1a73e8;
    }
  }

  .g {
    opacity: 0.5;
  }

  .tmn {
    z-index: 22;
    opacity: 1;
    cursor: move;
  }

  &-toolbar {
    flex: 0 0 50px;
    display: flex;
    align-items: center;
  }

  &-view {
    flex: 1 1 0%;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    position: relative;
  }

  &-head {
    display: flex;
    flex: 0 0 40px;
    align-items: stretch;
    border-bottom: 1px solid $mainBorderColor;
    font-size: 14px;
    box-sizing: border-box;

    &-tz {
      flex: 0 0 40px;
    }

    &-days {
      border-left: 1px solid #dcdee3;
      flex: 1 1 auto;
      overflow: hidden;
      scroll-behavior: auto;
      scrollbar-gutter: unset;
    }

    &-s {
      display: flex;
      height: 100%;
      margin-right: 10px;
    }

    > span {
      flex: 1 1 0%;
      justify-content: center;
      align-items: center;
      display: flex;
      box-sizing: border-box;

      &:last-child {
        border: none;
      }
    }
  }

  &-body {
    flex: 1 1 0%;
    display: flex;
    flex-direction: column;
    position: relative;
    overflow: hidden;
  }

  &-row {
    flex: 1 1 0%;
    display: flex;
    overflow: hidden;
    border-bottom: 1px solid $mainBorderColor;
    box-sizing: border-box;

    &:last-child {
      border: none;
    }

    .c-cell {
      border-right: 1px solid $mainBorderColor;

      &:last-child {
        border: none;
      }
    }
  }

  &-hcell {
    flex: 1 1 0%;
    box-sizing: border-box;
    display: flex;
    overflow: hidden;
    justify-content: center;
    flex-direction: column;


    &.today {
      .c-hcell-text {

      }
    }

    &-text {
      display: block;
      min-width: 24px;
      height: 24px;
      text-align: center;
      line-height: 24px;
      cursor: pointer;
      border-radius: 50%;

      &:hover {
        background: #eee;
      }
    }
  }

  &-cell {
    flex: 1 0 auto;
    z-index: 3;
    overflow: hidden;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;

    &-head {
      color: #3c4043;
      padding: 0 6px;
      margin: 4px 0;
      font-size: 12px;
      font-weight: 500;
      letter-spacing: .3px;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    &-events {
      flex-grow: 1;
      padding-right: 6px;
      position: relative;
      overflow: hidden;
    }

    &-text {
      display: block;
      min-width: 24px;
      height: 24px;
      text-align: center;
      line-height: 24px;
      cursor: pointer;
      border-radius: 50%;

      &:hover {
        background: #eee;
      }
    }

    &-more {
      line-height: 24px;
      color: #aaa;
      padding-right: 5px;
      cursor: pointer;
      white-space: nowrap;

      &:hover {
        color: #aaaaaa9c;
      }
    }

    &.inactive {
      .c-cell-text {
        color: #c3c3c3;
      }
    }
  }

  &-event {
    padding: 4px;
    box-sizing: border-box;
    cursor: pointer;
    border-radius: 4px;
    background-color: rgb(66, 133, 244);
    border: 1px solid #fff;
    color: #fff;
    position: relative;
    overflow: hidden;
    display: flex;
    font-size: 12px;

    &-title {
      width: 100%;
      flex: 1 1 0%;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      text-align: left;
      font-weight: 600;
      font-size: 1.05em;
    }

    &-time {
      text-align: left;
      white-space: nowrap;
    }

    &.active {
      z-index: 9999;
      transition: box-shadow 0.05s linear;
      box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12), 0 3px 5px -1px rgba(0, 0, 0, 0.2);
    }
  }

  &-event:hover {
    &:before {
      background-color: rgba(13, 13, 13, 0.09);
      -webkit-border-radius: 4px;
      border-radius: 4px;
      content: '';
      height: 100%;
      left: 0;
      position: absolute;
      right: 0;
      top: 0;
    }
  }
}
