.dnd {
  li {
    display: flex;
    align-items: center;
    margin-bottom: 5px;
  }

  .i-drag {
    font-size: 15px;
    margin-right: 6px;
    color: #929090;
  }

  .c-dnd {
    flex: 1 1 auto;
    flex-wrap: nowrap;
    display: flex;
  }
}
