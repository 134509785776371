:root {
  --irv-btn-height: 32px;
  --irv-btn-primary: #0066ff;
  --irv-btn-primary-color: #fff;
  --irv-btn-primary-hover: #005ce6;
  --irv-btn-primary-active: #0052cc;

  --irv-base-bg: #efefef;

  --irv-base-color: rgb(75, 75, 75);

  --irv-border: #cdcdcd;
  --irv-border-hover: #b3b3b3;
  --irv-border-focus: rgb(20, 115, 230);

  --irv-input-bg: #fff;

  --irv-select-item-height: 36px;
}

.irv-item, .irv-bitem {
  height: 100%;
  padding: 0 14px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: default;
}

.e-ring {
  outline: 2px solid dodgerblue;
  outline-offset: 2px;
}

@media screen and (max-width: 700px) {
  :root {
    --irv-select-item-height: 50px;
  }
}
