$borderColor: #d4d4d4;
$hoverCell: #6b6b6b;

.irv-list {
  display: flex;
  height: 100%;
  flex-direction: column;

  .irv-column {
    flex: 0 0 auto;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    padding: 0 6px;
    border-right: 1px solid $borderColor;
    position: relative;
    font-weight: 600;
  }

  & > .irv-list-header {
    border-bottom: 1px solid $borderColor;
  }

  &-header {
    height: 32px;
    flex: 0 0 auto;
    overflow: hidden;
    padding-left: 20px;
    background: #f4f4f4;
  }

  &-wh {
    display: flex;
    height: 100%;
  }

  &-content {
    flex: 1 1 auto;
  }

  &-row {
    display: flex;
    padding: 0 20px;

    input, textarea {
      height: 100%;
      width: 100%;
      border: 0;
      min-height: auto;
      background: transparent;
      resize: none;
      padding: 0 6px;
      box-sizing: border-box;

      &:hover, &:focus {
        background: #ececec;
      }
    }

    .react-datepicker__input-container, .react-datepicker-wrapper {
      height: 100%;
    }

    textarea, .ant-select-selector {
      height: 100% !important;
      border: 0 !important;
    }
  }

  &-cell {
    flex: 0 0 auto;
    display: flex;
    align-items: center;
    position: relative;

    &:last-child {
      border-right: 0;
    }
  }

  .rs {
    width: 4px;
    height: 100%;
    position: absolute;
    right: -2px;
    cursor: e-resize;
    z-index: 3;
    top: 0;

    &:hover {
      background: #4d90fe;
    }
  }

  &-add {
    display: flex;
    align-items: flex-end;

    button {
      cursor: pointer;
      background: transparent;
      border: 0;
      outline: none;
      height: 32px;
      border-radius: 4px;
      padding: 0 10px;
      text-align: left;

      &:hover {
        background: #eee;
      }
    }
  }

  .color {
    position: absolute;
    left: 3px;
    top: 50%;
    height: 80%;
    width: 4px;
    border-radius: 4px;
    transform: translateY(-50%);
  }
}
