.irv-collapse {
  &-panel {
    margin-bottom: 20px;
  }

  &-panel.open {
    .irv-collapse-expand {
      transform: rotate(0);
    }
  }

  &-header {
    display: flex;
    align-items: center;
    cursor: pointer;
  }

  &-expand {
    border: 0;
    background: none;
    outline: none;
    padding: 0;
    width: 28px;
    height: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
    transform: rotate(-90deg);
    transition: transform ease .05s;
    cursor: pointer;

    svg {
      fill: #d3cfcf;
    }
  }

  &-title {
    font-size: 1.2em;
    font-weight: 600;
    margin: 0 5px;
  }

  &-content {
    margin: 10px 0;
  }
}
