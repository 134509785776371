.irv {
  &-grid {
    --irv-header-border: #dddcda;

    --irv-border-r: #d8e0e4;
    --irv-border-c: #e8e7e7;
    --irv-border-freeze: #e8e7e7;

    --irv-group-header: #f7f7f7;
    --irv-group-header-1: #ededed;
    --irv-group-header-2: #e3e3e3;

    --irv-gg-bg: #ededed;
    --irv-gg-bg-1: #e3e3e3;

    --irv-border: #dde1e3;
    --irv-gh-border: #cacaca;
    --irv-gh-border-1: #c1c1c1;
    --irv-gh-border-2: #bdbdbd;

    --irv-form-ctrl-height: 100%;

    &.gd-0 {
      --irv-gg-bg: #fff;

      .gg-0 {
        border-right: 0;
        border-left: 0;
      }
    }

    &.gd-1 {
      --irv-gg-bg-1: #fff;

      .gg-1 {
        border-left: 0;
        border-right: 0;
      }
    }

    &.scrolled .irv-frz {
      box-shadow: 2px 0 0px 0px rgba(223, 223, 223, 0.75)
    }
  }

  &-sn {
    display: flex;
    height: 100%;
    align-items: center;
    padding-left: 10px;

    .color {
      position: absolute;
      top: 2px;
      bottom: 2px;
      left: 2px;
      width: 6px;
      border-radius: 4px;
    }

    .expand {
      position: absolute;
      right: 6px;
      align-items: center;
      background: #fff;
      height: 26px;
      cursor: pointer;
      outline: none;
      border-radius: 50%;
      display: none;
      border: 1px solid #e5e7f9;

      .icon {
        font-size: 13px;
        color: #1639e6;
      }

      &:hover {
        background: #e5e7f9;
      }
    }
  }

  &-cell {
    &.active {
      .irv-spc {
        box-shadow: 0 0 0 2px #2e7ff7;
        border-radius: 3px;

        &.edt {
          box-shadow: 0 0 0 3px #2e7ff7;
          border: 0;
          padding: 0 6px;
        }

        .tl-text {
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }
      }

      .lr {
        height: auto;
        flex-wrap: wrap;
        min-height: 100%;
        background: #fff;
      }

      .sl, .us {
        .irv-select-trigger {
          background: #fff;
        }
      }
    }

    .exp {
      background: transparent;
      border: 0;
    }

    .react-datepicker-wrapper, .react-datepicker__input-container {
      height: 100%;
    }
  }

  &-spc {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    border: 0;
    padding: 0 6px;
    outline: none;
    white-space: nowrap;
    text-overflow: ellipsis;

    textarea {
      overflow: auto;
    }

    &.tp {
      overflow: hidden;
    }

    &.tl {
      white-space: nowrap;
      color: inherit;
      justify-content: space-between;

      &.e-textarea {
        white-space: pre;
        padding: 5px 5px;
      }
    }

    &.sl {
      height: auto;
      min-height: 100%;

      .irv-select-value {
        padding: 0 6px;
      }

      .e-tag {
        height: 24px;
      }

      .e-tag {
        max-width: 100%;
        justify-content: flex-start;
        overflow: hidden;
      }

      &.ant-select:not(.ant-select-customize-input) {
        font-size: 13px;

        .ant-select-selector {
          background: none;
          padding: 0 6px;
          height: 100%;
          border: 0;
        }

        .e-tag {
          height: 23px;
        }
      }
    }

    &.ch {
      justify-content: center;
    }

    &.el {
      overflow: hidden;

      > a {
        text-overflow: ellipsis;
        overflow: hidden;
        border-bottom: 1px solid #1890ff;
      }

      > span {
        border-bottom: 1px solid rgba(0, 0, 0, 0.65);
      }
    }

    &.sc {
      overflow: hidden;

      > a {
        height: 100%;
        display: flex;
        align-items: center;
      }
    }

    &.nb {
      border: 0;
      overflow: hidden;

      > span {
        text-align: right;
        display: block;
        width: 100%;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }

    &.us {
      height: auto;
      min-height: 100%;

      .irv-select-trigger {
        padding: 0 6px;
      }

      &.ant-select {
        height: auto;
        min-height: 100%;
        display: flex;

        .ant-select-selector {
          border: 0;
          height: 100%;
          padding: 0 6px;
          width: 100%;
        }
      }

      .eyzy-user-badge {
        max-width: 100%;
        text-overflow: ellipsis;
        overflow: hidden;
        margin: 0 3px 0 0;
        font-size: 13px;
      }
    }

    &.lr {
      overflow: auto hidden;
      -ms-overflow-style: none;
      scrollbar-width: none;

      &::-webkit-scrollbar {
        display: none;
      }

      .e-btn {
        height: 22px;
        width: 22px;
        border: 1px solid #dfdada;

        .icon {
          font-size: 11px;
        }

        + .e-tag {
          margin-left: 10px;
        }
      }

      .e-tag {
        height: 23px;
        margin: 3px 4px 3px 0;
      }
    }

    &.fr {
      overflow: hidden;

      > span {
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }

    &.lp {
      > .irv-spc {
        box-shadow: none !important;
      }
    }

    &.an {
      button {
        height: 24px;
      }
    }
  }

  &-row {
    &--hover, &--active {
      z-index: 10;

      .irv-cell {
        background: #f9f9f9;
      }
    }

    &--active {
      z-index: 12;
    }

    &--hover {
      .expand {
        display: flex;
      }
    }
  }

  &-edit-clm {
    width: 100%;
    display: flex;

    &:hover {
      background-color: #f1f1f1;
      cursor: pointer;
    }
  }

  &-header {
    font-weight: 500;

    &-wrap, .irv-frz {
      background: #f6f6f6;
    }


    &-column {
      background: #f6f6f6;

      .column-def {
        padding: 0 10px;
      }
    }
  }

  &-ow {
    &.ant-tooltip {
      .ant-tooltip-arrow {
        display: none;
      }

      .ant-tooltip-inner {
        background: #fff;
      }
    }
  }

  &-summary {
    flex: 1 1 100%;
    display: flex;
    align-items: center;
    padding-right: 9px;
    overflow: hidden;
    justify-content: flex-end;

    &.ae {
      cursor: pointer;

      &:hover {
        background: #f1f1f1;

        &:not(.hv) {
          .agg {
            display: none;

            &-hint {
              cursor: pointer;
              display: flex;
              flex: 1 1 100%;
            }
          }
        }
      }
    }

    .agg {
      flex: 1 1 auto;
      white-space: nowrap;
      text-align: right;

      &-hint {
        display: none;
        width: 100%;
        height: 100%;
        align-items: center;
        justify-content: flex-end;
        color: #868686;
        flex: 0;

        .icon {
          font-size: 19px;
        }
      }

      > span {
        color: #919191;
        padding-right: 5px;
      }
    }
  }

  &-grp-by {
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    justify-content: space-between;
    padding: 3px 0;
    overflow: hidden;
    margin-right: 5px;

    &-col {
      font-size: 10px;
      text-transform: uppercase;
      white-space: nowrap;
    }

    &-value {
      font-weight: 500;
    }

    .irv-spc {
      padding: 0;
      background: transparent;
    }
  }

  &-modal {
    &.tl {
      height: auto;

      .irv-modal-content {
        padding: 4px;

        textarea {
          box-shadow: 0 0 0 2px #2e7ff7;
        }
      }
    }
  }
}
