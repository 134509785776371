.shape {
  display: flex;
  justify-content: center;
  align-items: center;

  &--rect {
    padding: 5px;
  }

  &--circle {
    border-radius: 50%;
  }
}