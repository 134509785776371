.irv-progress-bar {
  width: 100%;
  display: flex;
  align-items: center;

  .label {
    margin-right: 10px;
    font-size: 0.9em;
  }

  .bar {
    background: #d5d5d5;
    width: 100%;
    height: 6px;
    border-radius: 4px;
    position: relative;
    overflow: hidden;
  }

  .fill {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    background: #0164dc;
  }
}
