.df {
  display: flex;
  flex-wrap: nowrap;
  overflow: auto hidden;

  &-label {
    font-weight: 400;
    padding-right: 5px;
    white-space: nowrap;
  }

  &-value {
    font-weight: 600;
    white-space: nowrap;
  }

  &-d {
    .ant-drawer-footer {
      height: 80px;
    }
  }

  &-item + &-item {
    margin-left: 5px;
  }
}
