.irv {
  &-grid {
    &.gd-1 {
      .irv-fh {
        background: var(--irv-gg-bg-1);
      }
    }

    .gg-0 {
      background: var(--irv-gg-bg);
    }

    .gg-1 {
      background: var(--irv-gg-bg-1);
    }

    .irv-gf {
      &.gg-0 {
        background: var(--irv-gg-bg);
        border-left: 1px solid var(--irv-gh-border);
      }

      &.gg-1 {
        border-left: 1px solid var(--irv-gh-border-1);
      }
    }

    .irv-gfr {
      &.gg-0 {
        border-right: 1px solid var(--irv-gh-border);
      }

      &.gg-1 {
        border-right: 1px solid var(--irv-gh-border-1);
      }
    }
  }

  &-gl-0 {
    background: var(--irv-gg-bg);

    .irv-frz {
      background: var(--irv-gg-bg);
    }

    .irv-group-cell {
      background: var(--irv-group-header);
      border-bottom: 1px solid var(--irv-gh-border);
    }
  }

  &-gl-1 {
    background: var(--irv-gg-bg-1);

    .irv-frz {
      background: var(--irv-gg-bg-1);
    }

    .irv-group-cell {
      background: var(--irv-group-header-1);
    }
  }

  &-gl-2 {
    background: var(--irv-gg-bg-2);

    .irv-frz {
      background: var(--irv-gg-bg-2);
    }

    .irv-group-cell {
      background: var(--irv-group-header-2);
    }
  }

  &-group {
    display: flex;
    align-items: flex-end;

    &.collapsed {
      .irv-caret {
        svg {
          transform: rotate(-90deg);
        }
      }

      .irv-group-wrap {
        .irv-group-cell:last-child {
          border-bottom-right-radius: 4px;
        }
      }

      .irv-gf + .irv-group-cell {
        border-bottom-left-radius: 4px;
      }
    }

    &-wrap {
      display: flex;
      height: 50px;

      .irv-group-cell {
        &:last-child {
          border-top-right-radius: 4px;
        }
      }
    }

    &-cell {
      height: 50px;
      border-right: 1px solid var(--irv-gh-border);
      border-top: 1px solid var(--irv-gh-border);
      display: flex;
      flex: 0 0 auto;
      overflow: hidden;
    }

    .irv-frz {
      height: 100%;
      display: flex;
      align-items: flex-end;
      border-right: 1px solid var(--irv-border-freeze);

      .irv-group-cell {
        flex: 1 1 auto;
        border-right: 0;
      }
    }

    .irv-gf + .irv-group-cell, .irv-gf + .irv-group-wrap .irv-group-cell:first-child {
      border-left: 1px solid var(--irv-gh-border);
      border-top-left-radius: 4px;
    }
  }

  &-row {
    .irv-frz {
      border-right: 1px solid var(--irv-border-freeze);
      background: var(--irv-gg-bg);

      .irv-cell {
        border-right: 0;
      }
    }

    .irv-gf + .irv-cell, .irv-gf + .irv-rwrap .irv-cell:first-child {
      border-left: 1px solid var(--irv-gh-border);
    }
  }

  &-rwrap {
    flex: 1 1 auto;
    background: var(--irv-gg-bg);

    .irv-cell {
      &:last-child {
        border-right-color: var(--irv-gh-border);
      }
    }
  }

  &-grlast {
    .irv-cell {
      border-bottom: 1px solid var(--irv-gh-border);
    }

    .irv-gf + .irv-cell, .irv-gf + .irv-rwrap .irv-cell:first-child {
      border-bottom-left-radius: 4px;
    }

    .irv-rwrap {
      .irv-cell {
        &:last-child {
          border-bottom-right-radius: 4px;
        }
      }
    }
  }

  &-gf, &-gfr {
    height: 100%;
    flex: 0 0 auto;
  }

  &-ft {
    display: flex;

    &-fl { // заполнить пустоту
      flex-grow: 1;
      background: var(--irv-gg-bg);
      border-bottom: 1px solid var(--irv-gh-border);
    }

    .irv-frz {
      border-right: 1px solid var(--irv-border-freeze);
      background: var(--irv-gg-bg);
    }

    .irv-frz, .irv-rwrap {
      height: 100%;
    }

    .irv-gfr {
      &.gg-0 {
        border-bottom-right-radius: 4px;
        border-bottom: 1px solid var(--irv-gh-border);
      }
    }

    .irv-gf {
      &.gg-0 {
        border-bottom: 1px solid var(--irv-gh-border);
        border-bottom-left-radius: 4px;
      }
    }
  }
}
