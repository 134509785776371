.m-add-view {
  .irv-modal-content {
    background: #eee;
    padding: 0;
    position: relative;
  }

  .e-input {
    position: absolute;
    top: 10px;
    left: 210px;
    right: 15px;
    width: auto;
  }

  .help {
    padding: 10px 2px;
  }

  .e-btn {
    position: absolute;
    right: 15px;
    bottom: 10px;
  }

  .eyzy-tabs {
    &-header {
      width: 200px;
      background: #fff;
      padding: 20px 10px;

      &__item {
        border-radius: 4px;
        margin-bottom: 5px;
        display: flex;
        align-items: center;

        .icon {
          margin-right: 10px;
          font-size: 16px;
        }
      }
    }

    &-scroll {
      width: 100%;
    }

    &.vertical {
      .eyzy-tabs-header {
        &__item {
          border: 0;

          &.active {
            border: 0;
            background: #d4d4d4;
          }
        }
      }
    }

    &-content {
      padding: 40px 10px 15px 10px;
    }
  }
}

.view-users {
  display: flex;
  margin-left: 20px;

  .avatar {
    margin-left: 2px;
  }

  button {
    width: 30px;
    height: 30px;
    background: #e0dee5;
    border-radius: 50px;
    margin-left: 5px;
    border: 1px dashed #bcbac1;
  }
}
