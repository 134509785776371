@import "../../../../assets/style/variables";

$hoverColor: #d4d2d7;
$activeColor: #2b66ea;
$activeTextColor: #fff;
$ctrlHoverColor: #a2a1a1;
$activeTableBg: #d4d2d7;

$secondary: #8b8b8a;
$borderColor: #dddcda;

$base-color-link: #0C0C0C;

.ws-sb {
  background: $bgSidebar;
  border-right: 1px solid $borderColor;
  font-size: 12px;
}

.sb {
  &-header {
    flex: 0;
    padding: 10px 20px;
  }

  &-footer {
    button {
      display: flex;
      width: 100%;
      align-items: center;
      justify-content: flex-start;
      font-size: 14px;
      padding: 0 1rem;

      &:hover {
        background: #c7c5ca;
      }
    }

    .icon {
      font-size: 20px;
    }

    span {
      padding-left: 5px;
    }
  }

  &-actions {
    padding: 10px 20px;
    margin-bottom: 20px;
  }

  &-act {
    display: flex;
    width: 100%;
    line-height: 35px;
    color: #484848;
    border-radius: 6px;
    padding: 0 0 0 10px;
    font-size: 1.1em;
    align-items: center;

    .icon {
      font-size: 17px;
      margin-right: 10px;
    }

    &:hover {
      background: #e3e3e3;
      color: #484848;
    }
  }

  &-wss {
    flex: 1 1 auto;
    overflow: hidden auto;
  }

  &-ws {
    margin-bottom: 20px;
  }

  &-section-text {
    color: $secondary;
    padding: 10px;
    font-size: 0.9em;
    text-transform: uppercase;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  &-logo {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;

    .logo {
      height: 40px;
    }
  }

  &-ws {
    &.smpl {
      .sb {
        &-bases {
          padding: 0 10px;
        }

        &-base {
          &-title {
            a {
              &:hover {
                .tc {
                  border-bottom: 1px solid transparent;
                }
              }
            }
          }
        }

        &-table {
          &.active {
            &:before {
              left: -13px;
            }
          }
        }

        &-tables {
          padding-left: 20px;

          &:before {
            left: 8px;
          }
        }
      }
    }

    &:hover {
      .sb-ws-name {
        padding-right: 10px;

        .cha {
          display: flex;
        }
      }
    }

    &-name {
      padding: 0 20px;
      display: flex;
      align-items: center;
      cursor: pointer;
      color: #8c8c8c;
      font-weight: 600;
      margin-bottom: 10px;

      span {
        font-size: 0.9em;
        text-transform: uppercase;
        line-height: 26px;
        flex-grow: 1;
      }

      .cha.create {
        background: #dad8de;
        display: none;

      }
    }
  }

  &-bases {
    padding: 0 10px 0 5px;
    margin-bottom: 30px;
  }

  &-base {
    position: relative;
    margin-bottom: 2px;

    &:hover {
      .sb-base-ctrl {
        display: flex;
      }
    }

    &.active {
      .sb-base-link {
        background: $activeColor;
        color: $activeTextColor;

        a .tc {
          color: $activeTextColor;
        }

        &:hover {
          background: $activeColor;
        }
      }
    }

    &-link {
      border-radius: 6px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      cursor: pointer;
      position: relative;
      padding-right: 4px;
    }

    &-title {
      flex: 1 1 auto;
      border-radius: 6px;
      display: flex;
      overflow: hidden;
      align-items: center;
      height: 38px;
      padding: 0 2px;

      a {
        display: flex;
        align-items: center;
        overflow: hidden;

        .tc {
          border-bottom: 1px solid transparent;
        }

        &:hover {
          .tc {
            border-bottom: 1px solid;
          }
        }
      }
    }

    &-ctrl {
      display: none;
      align-items: center;
      flex: 1 0 auto;
      justify-content: flex-end;
    }

    &.expanded {
      .sb-base-link {
        .che {
          transform: translateX(-1px) rotate(90deg);
        }
      }
    }

    .sb-base-link {
      &:hover {
        background: $hoverColor;
      }
    }
  }

  &-tables {
    margin: 6px 0 6px 2px;
    padding-left: 25px;
    position: relative;

    &:before {
      content: '';
      display: flex;
      position: absolute;
      top: 0;
      left: 10px;
      bottom: 0;
      width: 3px;
      background: #c8c6c6;
    }
  }

  &-table {
    display: flex;
    align-items: center;
    padding: 0 10px;
    border-radius: 6px;
    margin-bottom: 2px;
    position: relative;
    height: 32px;

    &:hover {
      background: $hoverColor;

      .cha {
        display: flex;
      }
    }

    &.active {
      background: $activeTableBg;

      &:before {
        content: '';
        display: flex;
        position: absolute;
        top: 0;
        left: -16px;
        bottom: 0;
        width: 5px;
        background: $activeColor;
        border-radius: 3px;
      }
    }

    a {
      flex: 1 1 auto;
      line-height: 30px;
      color: $primary;

      &:hover {
        color: $primary;
      }
    }

    .cha {
      display: none;
    }
  }

  &-smenu {
    .din {
      min-width: 230px;
    }
  }

  &-footer {
    flex: 0 0 40px;
    display: flex;
    align-content: center;
  }

  .che {
    .icon {
      font-size: 20px;
    }
  }

  .che, .cha {
    background: none;
    border: 0;
    padding: 0;
    cursor: pointer;
    width: 26px;
    height: 26px;
    border-radius: 6px;
    flex: 0 0 26px;
    position: relative;
  }

  .cha {
    align-items: center;
    justify-content: center;

    .icon {
      font-size: 16px;
    }
  }

  .b-lock {
    font-size: 13px;
    flex: 0 0 26px;

    .icon {
      font-size: 12px;
    }
  }

  .base-link {
    height: 35px;
    border-radius: 6px;
    flex: 1 1 auto;
    overflow: hidden;
    color: $base-color-link;
    font-weight: 500;

    .icon {
      flex: 0 0 22px;
      height: 22px;
      margin: 0 8px;
      font-size: 14px;
    }
  }

  .edit-en {
    width: 100%;
    margin-top: 5px;
  }

  .collapse {
    display: none;
    position: absolute;
    top: 35px;
    right: 0;
    transform: translateX(13px);
    z-index: 999;
  }

  &:hover {
    .collapse {
      display: flex;
    }
  }
}

@media (max-width: 1024px) {
  .sb {
    .collapse {
      position: initial;
      padding: 10px;
      margin-right: 10px;
      display: flex;
    }
  }
}
