.login {
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  background: #0269fd;

  .logo {
    height: 40px;
  }

  img {
    margin: 25px;
  }

  &-form {
    display: flex;
    flex-direction: column;
    width: 470px;
    padding: 25px;
    background: #fff;
    text-align: center;
    border-radius: 4px;

    input {
      height: 3em;
      margin-bottom: 15px;
    }

    button {
      height: 3em;
    }

    [type="submit"] {
      display: inline-flex;
      justify-content: center;
      align-items: center;
      border-radius: 4px;
      border: 0 solid transparent;
      height: 3em;
      line-height: 3em;
      box-sizing: border-box;
      cursor: pointer;
      outline: none;
      padding: 0px 10px;
      font-weight: 500;
      transition: all 0.1s ease-out 0s;
      color: #fff;
      background-color: #2100FF;

      &.pr {
        opacity: 0.7;
        cursor: default;
      }
    }
  }
}


@media screen and (max-width: 470px) {
  .login-form {
    width: 90%;
  }
}
