.e-input, .e-textarea {
  min-height: 32px;
  height: auto;
  color: var(--irv-base-color);
  background-color: var(--irv-input-bg);
  border-radius: 4px;
  border-style: solid;
  border-width: var(--irv-border-width, 1px);
  border-color: var(--irv-border);
  padding: 3px 10px;
  margin: 0;
  text-indent: 0;
  width: 100%;
  box-sizing: border-box;
  transition: all 0.1s ease-out 0s;
  outline: none;
  text-overflow: ellipsis;
  overflow: visible;
  -webkit-appearance: none;
  -moz-appearance: textfield;

  &::placeholder {
    color: #a7b1bb;
  }

  &[disabled] {
    //background: $base-color;
  }

  &:hover {
    border-color: var(--irv-border-hover);
    background: var(--irv-input-bg-hover);
  }

  &:focus {
    border-color: var(--irv-border-focus);
    background: var(--irv-input-bg-focus);
    box-shadow: none;
  }

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &[type=number] {
    -moz-appearance: textfield;
  }
}
