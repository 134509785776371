.react-datepicker {
  border: 0;
  -webkit-box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12), 0 3px 5px -1px rgba(0, 0, 0, 0.2);
  box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12), 0 3px 5px -1px rgba(0, 0, 0, 0.2);
  display: flex;

  &__header {
    background: #fff;
    border: 0;
  }

  &__navigation {
    border-radius: 4px;

    &:hover {
      background: #f6f6f6;
    }

    &-icon--next {
      left: 0;
    }

    &-icon--previous {
      right: 0;
    }

  }

  &-popper {
    z-index: 800;
  }

  &-year-header {
    padding: 10px 0;
  }

  &__month-text {
    padding: 5px;
  }
}

@media (max-width: 767px) {
  .react-datepicker {
    &__month {
      width: 240px;

      .react-datepicker__month-text {
        width: 70px;
      }
    }

    &__navigation {
      background: #f6f6f6;

      &:active {
        background: #e2e1e1;
      }

      //&-icon {
      //  &:before {
      //    top: 12px;
      //  }
      //}
    }
  }
}