@import '../../assets/style/variables';

$slugHeight: 90px;

.linked-records {
  &-slug {
    height: $slugHeight;
    position: relative;
  }

  &-modal {
    .t-name {
      margin: 0 10px 25px 10px;
    }

    &.ant-drawer, &.ant-modal {
      z-index: $z-linked-records-modal;
    }

    .ant-modal-header, .ant-drawer-header {
      padding: 8px 16px;

      .eyzy-input {
        border: 0;
      }
    }

    .ant-modal-body {
      min-height: 110px;
      position: relative;
    }

    .ant-drawer-footer {
      height: 80px;
      padding-top: 10px;

      button {
        margin-right: 10px;
      }
    }

    .ant-modal-footer {
      button {
        margin-left: 10px;
      }
    }

    .group-header {
      display: flex;
      align-items: center;

      .checkbox-wrap {
        display: block;
        width: 18px;
        height: 18px;
        margin-right: 10px;
      }
    }
  }

  &__row {
    height: $slugHeight;
    border: 2px solid #e5e5e5;
    border-radius: 4px;
    box-sizing: border-box;
    margin-bottom: 10px;
    cursor: pointer;
    position: relative;

    .body {
      display: flex;
      overflow: auto;
      padding: 10px;
      width: 100%;
      height: 100%;
    }

    &.active {
      border-color: #798fee;
    }
  }

  &__row.expanded {
    height: auto;

    .body {
      flex-direction: column;
    }

    .linked-records__col {
      margin: 0 0 20px 0;
      overflow: initial;
    }

    .col-name {
      margin-bottom: 10px;
    }

    .col-value {
      margin-left: 5px;
    }
  }

  &__col {
    margin-right: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-width: 20%;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .eyzy-checkbox {
    display: flex;
    margin: 0 20px 0 10px;
  }

  .remove {
    position: absolute;
    right: -10px;
    top: -10px;
    width: 20px;
    height: 20px;
    background: #d2d2d2;
    border-radius: 50%;
  }

  .col-name {
    color: $secondary-color;
  }

  .col-value {
    .grid-value {
      margin: 0;
    }

    .grid-number {
      text-align: left;
    }
  }
}

@media screen and (max-width: 900px) {
  .linked-records {
    &__col {
      min-width: 200px;
    }
  }
}