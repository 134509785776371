.irv {
  &-header {
    height: 32px;
    display: flex;
    width: 100%;
    overflow: hidden;
    border-bottom: 1px solid var(--irv-header-border);

    &-wrap {
      display: flex;
      flex: 1 0 auto;
      background: var(--irv-g-header-bg);
    }

    &-column {
      height: 100%;
      display: flex;
      border-right: 1px solid var(--irv-header-border);
      box-sizing: border-box;
      position: relative;
      flex: 0 0 auto;
    }

    .irv-frz {
      justify-content: flex-end;
      background: #fff;
    }

    .sn {
      border: 0;
    }
  }

  &-rs {
    width: 4px;
    height: 100%;
    position: absolute;
    right: -2px;
    cursor: e-resize;
    z-index: 3;
    top: 0;

    &:hover {
      background: #4d90fe;
    }
  }
}
