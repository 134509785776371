.e-table {
  overflow: auto;
  position: relative;
  isolation: isolate;
  padding-bottom: 15px;
  border-radius: 4px;

  &.bordered {
    border: 1px solid rgba(1, 1, 1, .1);
    border-radius: 4px;
    border-bottom: 0;
    padding: 0;

    th, td {
      border-inline-start: 1px solid rgba(1, 1, 1, .1);

      &:first-child {
        border-inline-start: none;
      }
    }

    .irv-caret, .e-table-group .e-checkbox {
      border-right: 1px solid rgba(1, 1, 1, .1);
    }
  }

  &.scrolled {
    .fix-last {
      border-inline-end: 1px solid rgba(1, 1, 1, .1);

      &:before {
        box-shadow: inset 10px 0 8px -8px rgb(5 5 5 / 6%);
      }
    }
  }

  &.grouped {
    thead {
      th {
        background: #fff;
      }
    }
  }

  &:not(.bordered) {
    thead {
      th {
        &:before {
          position: absolute;
          top: 50%;
          inset-inline-end: 0;
          width: 1px;
          height: 2.6em;
          background-color: rgba(5, 5, 5, .06);
          transform: translateY(-50%);
          transition: background-color .3s;
          content: "";
        }
      }
    }

    &.scrolled {
      thead {
        th {
          &:before {
            width: 0;
          }
        }
      }
    }
  }

  table {
    width: 100%;
    position: relative;
    border-block: inherit;
    text-align: start;
    border-collapse: separate;
    border-spacing: 0;
    border: 0;

    &.fixed {
      table-layout: fixed;
    }
  }

  thead {
    position: sticky;
    top: 0;
    z-index: 3;
  }

  th {
    background: #f6f6f6;
    padding: 12px var(--irv-table-cell-padding, 10px);
    position: relative;
    text-align: left;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    border-bottom: 1px solid rgba(1, 1, 1, .1);
    font-size: 0.9em;

    &.gp {
      border-inline-end: 0;
    }
  }

  td {
    padding: 0 var(--irv-table-cell-padding, 10px);
    background: #fff;
    height: 48px;

    &.gp {
      border-inline-end: 0;
    }
  }

  &-row {
    td {
      overflow: hidden;
    }
  }

  &-s {
    td {
      height: 40px;
    }

    .e-table-group-header {
      height: 38px;
    }
  }

  &-l {
    td {
      height: 56px;
    }
  }

  tbody {
    tr {
      &.selected {
        td {
          background: #e9f0ff;
        }

        &:hover {
          td {
            background: #d9e4fb;
          }
        }
      }

      > td {
        border-top: 1px solid rgba(1, 1, 1, .1);
      }

      &:hover {
        td {
          background: #f5f5f5;
        }
      }

      &:first-child {
        > td {
          border-top: 0;
        }
      }

      &:last-child {
        > td {
          border-bottom: 1px solid rgba(1, 1, 1, 0.1);
        }
      }
    }
  }

  .fix {
    z-index: 2;
  }

  .fix-last {
    z-index: 2;

    &:before {
      position: absolute;
      top: 0;
      right: 0;
      bottom: -1px;
      width: 30px;
      transform: translateX(100%);
      transition: box-shadow .3s;
      content: "";
      pointer-events: none;
    }
  }

  .pin-right {
    position: sticky;
    right: 0;
    border-inline-start: 1px solid rgba(1, 1, 1, .1);
  }

  .collapsed {
    .irv-caret {
      svg {
        transform: rotate(-90deg);
      }
    }
  }

  &-group {
    display: inline-flex;
    height: 100%;
    align-items: center;
    position: sticky;
    left: 0;
    flex-wrap: nowrap;

    &-header {
      cursor: pointer;

      td {
        height: 48px;
        padding: 0;
        background: #f9f9f9;
      }
    }

    &-value {
      margin-left: 10px;
    }

    .irv-caret {
      flex: 0 0 57px;
      padding: 0;
      height: 100%;
    }
  }
}

.irv-table {
  overflow: auto;
  position: relative;
  isolation: isolate;

  table {
    width: 100%;
    position: relative;
    border-block: inherit;
    text-align: start;
    border-collapse: separate;
    border-spacing: 0;
    border: 0;

    &.fixed {
      table-layout: fixed;
    }
  }

  thead {
    position: sticky;
    top: 0;
    z-index: 3;
  }

  &.scrolled {
    .fix-last {
      border-inline-end: 1px solid rgba(1, 1, 1, .1);

      &:before {
        box-shadow: inset 10px 0 8px -8px rgb(5 5 5 / 6%);
      }
    }
  }

  .fix {
    z-index: 2;
    left: 0;
  }

  .fix-last {
    z-index: 2;
    left: 0;

    &:before {
      position: absolute;
      top: 0;
      right: 0;
      bottom: -1px;
      width: 30px;
      transform: translateX(100%);
      transition: box-shadow .3s;
      content: "";
      pointer-events: none;
    }
  }

  .pin-right {
    position: sticky;
    right: 0;
    background: #fff;
    z-index: 2;
    border-inline-start: 1px solid rgba(1, 1, 1, .1);
    border-bottom: 1px solid rgba(1, 1, 1, .1);
  }

  .th-cell {
    text-align: left;
    padding: 12px 16px;
    font-weight: 500;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    &:first-child {
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
    }

    &:last-child {
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
    }
  }

  .row {
    &:last-child {
      .pin-right {
        border-bottom: 0;
      }
    }
  }

  .row-cell {
    background: #fff;
    height: 48px;
    padding: 6px 16px;

    .e-checkbox {
      vertical-align: bottom;
    }
  }

  .no-data {
    text-align: center;
  }

  .irv-group-header {
    height: 48px;
    display: flex;
    align-items: center;
    cursor: pointer;
  }

  &-rows {
    .row {
      display: flex;

      &-cell {
        flex: 0 0;
        display: flex;
        align-items: center;
      }
    }
  }

  &-header {
    display: flex;
  }
}


// THEMES
.irv-table.bs {
  table {
    border-spacing: 1px;
  }

  .th-cell {
    background: #f5f6fa;
  }

  .row {
    &.selected {
      .row-cell {
        background: #f5f6fa;
      }

      &:hover {
        .row-cell {
          background: #eff2fb;
        }
      }
    }

    &-cell {
      &:first-child {
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
      }

      &:last-child {
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
      }
    }
  }
}

.irv-table.mn {
  border: 1px solid #d8dee4;
  border-radius: 4px;

  &.abl {
    .row {
      cursor: pointer;

      &:hover {
        .row-cell {
          background: #f6f8fa;
        }
      }

      &.expanded {
        &:hover {
          .row-cell {
            background: #6585ff;
          }
        }
      }
    }
  }

  &.expandable {
    .irv-caret {
      background: #e8eaf2;
      width: 30px;
      height: 30px;
      border-radius: 4px;
    }
  }

  .expanded-data {
    position: relative;

    > td {
      padding: 20px;
    }

    &:not(:last-child) {
      td {
        border-bottom: 1px solid #d8dee4;
      }
    }

    .details-block {
      margin: 0;
    }
  }

  .expanded {
    .row-expand {
      > .irv-caret {
        svg {
          transform: rotate(180deg);
        }
      }
    }

    .row-cell {
      background: #0969d1;
      color: #fff;
    }

    .e-tag {
      color: initial;
    }

    .bar {
      border: 1px solid #fff;
    }
  }

  &.grouped {
    border: 0;

    .th-cell {
      background: #fff;
      border: 0;
      flex: 0 0;
      box-sizing: border-box;
    }

    .row {
      &:last-child {
        .row-cell {
          border-bottom: 1px solid #d8dee4;
        }
      }

      &-cell {
        border-bottom: 1px solid #d8dee4;
        border-left: 1px solid #d8dee4;

        &:last-child {
          border-right: 1px solid #d8dee4;
        }
      }
    }

    &.scrolled {
      .e-table-group {
        border-left: 1px solid #d4d4d4;
      }
    }

    .irv-caret {
      flex: 0 0 57px;
      padding: 0;
      height: 100%;
    }
  }

  .irv-group-header {
    background: #f6f8fa;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    border: 1px solid #d8dee4;

    &:hover {
      background: #f3f8fd;
    }

    &:active {
      background: #f6f8fa;
    }

    &.collapsed {
      border-radius: 4px;

      .irv-caret {
        svg {
          transform: rotate(-90deg);
        }
      }
    }
  }

  .irv-table-group {
    border-radius: 4px;
    margin-bottom: 20px;

    &:last-child {
      margin: 0;
    }
  }

  .th-cell {
    background: #f6f8fa;
    font-weight: bold;
    border-bottom: 1px solid #d8dee4;
    border-radius: 0;
    flex: 1 1 auto;
  }

  .row {
    &.selected {
      .row-cell {
        background: #f7f9fc;
      }

      .row-checkbox {
        position: relative;

        &:before {
          content: '';
          display: block;
          position: absolute;
          left: 0;
          top: 0;
          width: 2px;
          height: 100%;
          background: #0e6fde;
        }
      }
    }

    &-cell {
      border-bottom: 1px solid #d8dee4;
    }

    &:last-child {
      .row-cell {
        border-bottom: none;
      }
    }
  }
}

.irv-table.hg {
  table {
    padding: 0 20px;
    background: #f3f5f7;
  }

  .th-cell {
    background: #f3f5f7;
    color: #adb4c0;
    text-transform: uppercase;
  }

  .row {
    &-cell {
      background: #f3f5f7;
      border-bottom: 1px solid #e2e6ed;
    }

    &:last-child {
      .row-cell {
        border-bottom: none;
      }
    }
  }
}

.irv-table.ww {
  border: 1px solid #ebeaef;
  border-radius: 4px;

  .th-cell {
    background: #fff;
    color: #adb4c0;
    text-transform: uppercase;
    border-bottom: 1px solid #f0f2f1;
  }

  .row {
    &.selected {
      .row-cell {
        background: #f4f8ff;
      }
    }

    &-cell {
      background: #fff;
      border-bottom: 1px solid #f0f2f1;
    }

    &-checkbox {
      border-right: 1px solid #f0f2f1;
    }

    &:last-child {
      .row-cell {
        border-bottom: none;
      }
    }
  }

  &.abl {
    .row {
      cursor: pointer;

      &:hover {
        .row-cell {
          background: #f6f8fb;
        }
      }
    }
  }
}
