.ws-view {
  &-header {
    justify-content: space-between;

    .stg {
      border: 1px solid #dddcda;

      &-o {
        background: #dddcda;
      }
    }

    .filter-fields, .sort-fields {
      padding: 15px;
    }
  }

  &-hbase {
    .eyzy-input {
      border-color: #dddcda;
    }
  }

  &-ctrl {
    .e-btn {
      margin-left: 5px;
      max-width: 260px;

      > span {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }
  }

  &-settings {
    &.ant-drawer {
      top: 110px;

      .ant-drawer-body {
        padding: 10px;
      }
    }

    &.ant-drawer-right.ant-drawer-open.no-mask {
      right: 0;
      transform: none;
    }

    &.ant-drawer-right.ant-drawer-open {
      .ant-drawer-content-wrapper {
        box-shadow: none;
        border-top: 1px solid #cbcfd0;
        border-left: 1px solid #cbcfd0;
      }
    }

    .abtn {
      display: flex;
      flex-direction: column;
      width: 100%;
      border-radius: 4px;
      border: 2px dashed #dddcda;
      background: #fafafa;
      align-items: center;
      padding: 20px;
      cursor: pointer;
      outline: none;

      img {
        margin-top: 20px;
      }

      &:hover {
        border-color: #abaaa9;
      }
    }
  }
}
