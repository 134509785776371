.sc, .el, .ph, .nb {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.el, .lk, .ph {
  text-decoration: underline;
}

.lr {
  .ss, .ms {
    .e-tag {
      margin: 5px;
    }
  }

  > .e-tag {
    margin: 3px;
    max-width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .linked-entries-btn {
    border-color: var(--irv-border);
    border-width: var(--irv-border-width);
  }
}

.td {
  .e-btn {
    border-color: var(--irv-border);
    border-width: var(--irv-border-width);
  }

  > .icon {
    display: none;
  }

  &:hover {
    > .icon {
      display: block;
    }
  }
}

.fr {
  width: 100%;
}

textarea.fvt {
  width: 100%;
  height: 100%;
  border: 0;
  resize: none;
  overflow: hidden;
  cursor: default;
  box-shadow: none;
  outline: none;
}

.tl {
  white-space: pre;
}

.us, .ms {
  .e-tag {
    max-width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
    margin: 3px 5px 3px 0;
  }
}

.td {
  position: relative;
  width: 100%;

  > .icon {
    position: absolute;
    top: 50%;
    right: 20px;
    transform: translateY(-50%);
  }
}

@media (max-width: 767px) {
  .irv-frm {
    --irv-select-bg: #f9f9f9;

    .irv-select-trigger {
      &:focus {
        box-shadow: 0 0 0 2px rgba(24, 115, 230, .4);
      }
    }

    .cc {
      .e-checkbox {
        width: 100%;
      }
    }

    .td {
      > .icon {
        display: none;
      }

      .e-btn {
        .icon {
          margin-right: 7px;
        }
      }
    }
  }
}
