@import "../../../assets/style/variables";


.collaborator {
  &-role {
    .irv-bitem {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }

    .name {
      font-weight: bold;
      margin: 5px 0;
    }

    .descr {
      color: $gray-color;
    }
  }
}

.collaborators {
  --irv-form-ctrl-height: 32px;

  &-block {
    h3 {
      opacity: .6;
      margin-bottom: 10px;
      font-size: 0.9em;
    }
  }

  &-footer {
    .e-btn {
      margin-left: 10px;
    }
  }

  .collaborator {
    display: flex;
    align-items: center;
    padding: 5px 0;
    justify-content: space-between;

    &-role {
      .irv-bitem {
        display: flex;
      }
    }

    &-name {
      display: flex;
      flex-direction: column;
      margin: 0 10px;
      flex: 1 1 auto;

    }

    &-role {
      width: 150px;
      margin: 0 10px;
    }

    .color-picker {
      margin-right: 10px;
    }

    .eyzy-switch-container {
      margin: 0 30px;
    }

    .stub {
      display: block;
      width: 30px;
    }

    .avatar {
      flex: 0 0 30px;
    }

    .remove {
      margin-left: 10px;
    }
  }

  .eyzy-tabs {
    margin-bottom: 20px;

    &-content {
      &__item {
        display: flex;
      }

      .eyzy-input {
        flex-grow: 1;
        margin-right: 10px;
      }

      .eyzy-btn {
        margin-left: 10px;
      }
    }
  }
}

.role-dd {
  .descr {
    color: $gray-color;
    font-size: 0.8em;
  }
}
