// https://tailwindcss.com

$bgSidebar: #E0DEE6;
$bgHeader: #fff;
$bgMainContent: #fff;
$bgView: #fff;

$primary: rgb(50, 50, 50);
$secondary-color: #dadce0;
$gray-color: #a2a5aa;
$border-radius: 4px;

$mainExtraElementColor: #549cfb;

$mobile-bg: #f2f3f6;

$max-width: 1120px;

/// z-indexes
$z-widget-modal: 10;
$z-linked-records-modal: 20;

$mobileBtnColor: red;
$mobileFormBG: #f2f1f1;
$mobileCtrlBG: #f0f3f4;

$sidebarWidth: 270px;

$uiBorder: #dddcda;
