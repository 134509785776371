:root {
  --irv-btn-primary-color: #fff;
}

.e-btn {
  height: var(--irv-btn-height);
  box-sizing: border-box;
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  overflow: visible;
  margin: 0;
  padding: 0 12px;
  font-weight: 600;
  border-style: solid;
  text-transform: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  vertical-align: top;
  text-decoration: none;
  line-height: 1.3;
  -ms-user-select: none;
  user-select: none;
  -webkit-user-select: none;
  -ms-touch-action: none;
  touch-action: none;
  cursor: pointer;
  isolation: isolate;
  transition: background-color 0.2s ease-out, color 0.2s ease-out, box-shadow 0.2s ease-out;
  background-color: #f3f3f3;
  border-radius: 4px;
  border-color: transparent;
  border-width: 0;

  &:hover {
    background-color: #edecec;
    color: rgb(44, 44, 44);
    border-color: #fff;
  }

  &:active {
    background-color: #dddcdc;
  }

  &-primary {
    background: var(--irv-btn-primary);
    color: var(--irv-btn-primary-color);

    &:hover {
      background-color: var(--irv-btn-primary-hover);
      color: var(--irv-btn-primary-color);
    }

    &:active {
      background-color: var(--irv-btn-primary-active);
      color: var(--irv-btn-primary-color);
    }
  }

  &-minimal {
    background-color: #fff;
    border: 0;

    &:hover {
      background-color: #f3f3f3;
      color: rgb(44, 44, 44);
      border-color: #fff;
    }

    &:active {
      background-color: rgb(234, 234, 234);
    }
  }
}
