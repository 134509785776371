.row-details {
  &-form {
    padding: 20px;
  }

  &-header {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-right: 5px;

    .e-btn {
      width: 36px;
    }

    .icon {
      font-size: 16px;
    }

    .bo:hover {
      background: #f3f3f3;
    }
  }

  &-name {
    margin-bottom: 20px;
    font-size: 30px;

    input {
      border: 0;
    }

    > .re-value {
      margin-bottom: 40px;
    }
  }

  &-modal {
    .irv-modal-content {
      padding: 0;
    }

    .irv-frm {
      padding: 32px;
    }

    .lookup-value {
      > div:not(:last-child) {
        margin-bottom: 10px;
      }
    }
  }

  &-form {
    .linked-records {
      margin-top: 10px;
    }

    .ant-collapse {
      margin-top: 30px;
      background: #fff;

      &-header {
        background: #efefef;
      }

      > .ant-collapse-item {
        border: 0;
      }

      &-content-box {
        background: #fff;

        .form-item:first-child {
          margin-top: 20px;
        }
      }
    }
  }

  &-tooltip {
    .ant-tooltip-content {
      width: 500px;
    }
  }

  &-mb {
    &-content {
      display: flex;
      align-items: center;

      > .re-value {
        flex: 1 1 auto;
        display: flex;
        align-items: center;
      }
    }

    .bo {
      border-radius: 50%;
      border: 1px solid #eee;
      margin-left: 20px;
      height: 40px;
      width: 40px;
      background: #ececec;

      &:active {
        background: #e4e4e4;
      }
    }

    .eyzy-textarea {
      height: 76px;
      line-height: 20px;
    }

    .ant-drawer-content, .ant-drawer-body {
      overflow: visible;
    }

    .ant-select {
      width: 100%;
    }
  }
}

.share-row {
  --irv-form-ctrl-height: 32px;

  .eyzy-switch {
    &-container {
      flex-direction: row-reverse;
      height: 40px;
      align-items: center;
      padding: 0 10px;
      cursor: pointer;
      justify-content: space-between;
      width: 100%;

      &:hover {
        background: #f4f4f4;
      }

      .icon {
        font-size: 25px;
        margin-right: 8px;
      }

    }

    &-text {
      display: flex;
      margin-right: 10px;
      align-items: center;
      flex: 1 1 auto;
    }
  }

  &-link {
    .e-btn {
      margin-left: 10px;
    }
  }

  .loading {
    position: relative;
    justify-content: center;
    display: flex;
    margin-top: 30px;
  }

  .remove {
    margin-left: 8px;
  }

  &-link:not(:last-child) {
    width: 100%;
    border-bottom: 1px solid #eee;
    margin-bottom: 10px;
  }

  &-view {
    &.ant-select-dropdown {
      z-index: 60000;
    }
  }

  &-container {
    display: flex;
    flex-direction: column;
    max-height: 230px;
    overflow: auto;
  }

  &-details {
    display: flex;
    align-items: center;
    padding: 10px 20px;

    .e-input[disabled] {
      background: #fff;
    }
  }

  &-s-view {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 10px 0;
    padding: 0 16px;

    .irv-select {
      width: 230px;
    }
  }

  &-footer {
    padding: 5px 10px;
    border-top: 1px solid #f4f3f3;
  }
}

.profile-details {
  --irv-form-label-color: #b5b5b5;

  .profile-name {
    font-size: 26px;
    padding: 3px;
    margin: 10px 0 5px;
    --irv-input-bg: transparent;
    --irv-border: transparent;
    --irv-input-bg-hover: transparent;
  }

  .details-block {
    padding: 15px;
  }

  min-height: 100%;
  display: flex;

  .irv-table {
    max-width: 724px;
  }

  .irv-frm {
    padding: 0;

    &-inline {
      --irv-input-bg: transparent;
      --irv-select-bg: transparent;
      --irv-select-bg-hover: #fff;
      --irv-select-bg-focus: #fff;
      --irv-border: transparent;
      --irv-input-bg-hover: #fff;
      --irv-input-bg-focus: #fff;
      --irv-border-hover: #d2d2d2;
      --irv-select-border-hover: #d2d2d2;

      .ph {
        padding-right: 5px;

        &:hover {
          padding-right: 40px;
        }
      }
    }

    &-label {
      width: 40%;
      flex: 0 0 40%;
      max-width: 40%;
      color: rgb(66, 82, 110);
      font-size: 12px;
      font-weight: 600;
      letter-spacing: -0.12px;
      align-self: flex-start;
      line-height: var(--irv-form-ctrl-height);
    }

    &-value {
      flex: 1 1 auto;
      width: 60%;
      color: rgb(9, 30, 66);
      word-break: break-word;
    }

    &-row {
      gap: 10px;
    }

    .column-def {
      > .icon {
        display: none;
      }
    }

    .irv-select-trigger {
      > .icon {
        display: none;
      }
    }
  }

  .irv-spc {
    flex-wrap: wrap;
    white-space: break-spaces;
  }

  &-info {
    max-width: 360px;
    width: 30%;
    flex: 0 0 30%;
    border-right: 1px solid #d8dee4;
    background: #f6f8fa;

    > h1 {
      margin-bottom: 1.5em;
      line-height: 1.1em;
    }

    .details-block {
      .irv-frm {
        width: 100%;
        background: transparent;
        box-shadow: none;
        padding: 0;
      }
    }
  }

  &-tabs {
    flex: 1 0 auto;
    padding: 20px;

    .eyzy-tabs {
      &-header {
        border: 0;
        margin-bottom: 15px;

        &__item {
          border-radius: 4px;
          font-weight: bold;
          padding: 6px 10px;
          display: flex;
          align-items: center;
          margin-right: 10px;

          &:hover {
            background: #eff2fa;
            color: #5e5e5e;
          }

          &.active {
            background: #e8efff;
            color: #7494db;
            border-color: transparent;
          }

          .icon {
            margin-left: 7px;
          }
        }
      }

      .details-block {
        padding: 0;
      }
    }
  }
}

.details-block {
  margin-bottom: 40px;

  > h3 {
    margin-bottom: 15px;
    font-size: 1.3em;

    .e-tag {
      font-size: 0.8em;
      margin-left: 10px;
      border: 0;
    }
  }
}

@media (max-width: 1024px) {
  .row-details {
    &-header {
      .bo {
        margin-left: 20px;
      }
    }

    &-mb-content {
      .eyzy-checkbox {
        padding: 15px;
      }
    }
  }
}

@media (max-width: 700px) {
  .share-row {
    &-container {
      margin-bottom: 100px;
    }

    &-s-view {
      flex-direction: column;
      align-items: flex-start;
    }

    &-label {
      margin-bottom: 10px;
    }

    .eyzy-switch-container {
      padding: 30px 20px;
    }
  }

  .profile-details {
    flex-direction: column;

    &-info {
      flex: 0 0 auto;
      width: 100%;
      max-width: 100%;
    }

    &-tabs {
      padding: 10px 5px;

      .eyzy-tabs-header {
        padding: 0 10px;
      }
    }

    .irv-frm {
      --irv-input-bg: #fff;
      --irv-select-bg: #fff;

      &-value {
        width: 100%;
      }

      &-label {
        line-height: 18px;
      }
    }
  }

  .details-block > h3 .e-tag {
    margin-top: 10px;
  }
}
