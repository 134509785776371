.eyzy-radio {
  &-group {
    padding: 3px;
    background-color: rgb(239, 242, 245);
    border-radius: 8px;
    display: inline-flex;
    -webkit-box-align: center;
    align-items: center;
    user-select: none;
    position: relative;
    border: 1px solid #eff2f5;
    height: 32px;
    box-sizing: border-box;

    > label {
      padding: 1px 9px;
      border-radius: 6px;
      height: 100%;
      transition: all 0.1s ease 0s;
      z-index: 2;
      cursor: pointer;
      color: #58667e;
      font-size: 12px;
      font-weight: 600;
      white-space: nowrap;
      color: rgb(88, 102, 126);
      display: flex;
      align-items: center;

      &.active {
        background-color: #fff;
        box-shadow: rgb(228, 231, 235) 0px 1px 0px;
      }

      input {
        display: none;
      }
    }

    > label + label {
      margin-left: 6px;
    }
  }
}