@import "../../../../../assets/style/variables";

li.view-field {
  cursor: pointer;

  .i-drag {
    cursor: grab;
  }

  &:hover {
    background: #f4f4f4;
  }
}

.view-field {
  .actions {
    margin-left: 5px;

    .e-btn {
      margin-right: 2px;
    }
  }

  &:hover {
    .column-def {
      .icon {
        display: none;
      }

      .i-drag {
        display: block;
      }
    }
  }
}

.sort-fields {
  padding: 10px 17px;

  li {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }

  .column-selector {
    width: 180px;
    margin: 0 10px;
  }

  .mdi-drag {
    margin-right: 10px;
  }

  .remove {
    margin-left: 8px;

    &:hover {
      background: #f5f5f5;
    }
  }

  &-prefix {
    width: 90px;
  }
}

.ws-view-settings {
  .din {
    margin-bottom: 20px;
  }

  .column-settings-item {
    padding: 0 10px;

    h3 {
      font-size: 1em;
      margin: 15px 0 10px;
    }
  }

  .ant-drawer-close {
    padding: 15px 25px;
  }

  .ant-drawer-header {
    padding: 0 20px;
    height: 50px;
    display: flex;
  }

  .ant-drawer-title {
    display: flex;
    align-items: center;

    .icon {
      color: #878787;
      font-size: 17px;
    }

    .e-btn {
      width: 30px;
      height: 30px;
      margin-right: 7px;
    }
  }

  .column-settings-row {
    padding: 0 6px;
  }

  .column-settings {
    .cm {
      margin-top: 5px;
      border-top: 1px solid #e6e3e3;
    }
  }

  .wtm + .column-settings-item {
    padding-top: 5px;
    border-top: 1px solid #e6e3e3;
  }
}

.view-settings {
  padding-bottom: 70px;

  &-tt {
    padding: 20px;
    min-width: 100px;
  }

  > .e-input {
    margin-bottom: 20px;
  }

  &-header {
    display: flex;
    align-items: center;
    border-radius: 4px;
    padding: 6px 10px;
    flex: 1 1 100%;
    justify-content: space-between;

    .text {
      display: flex;
      align-items: center;
    }

    .extra {
      color: $gray-color;
      padding-right: 25px;
    }

    &:hover {
      background: #eceaea;
    }

    .icon {
      margin-right: 10px;
      font-size: 16px;
    }
  }

  &-item {
    padding: 20px 10px;

    + .view-settings-item {
      margin-top: 70px;
    }
  }

  .cm {
    margin-top: 10px;
    padding-top: 10px;
    border-top: 1px solid #e6e3e3;

    .ant-menu {
      padding: 0;
    }
  }

  .ant-collapse-item-active {
    .view-settings-header {
      .text {
        font-weight: 600;
      }
    }
  }

  .ant-collapse-icon-position-right > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow {
    top: 0;
    padding-top: 11px;
  }

  .ant-collapse-borderless {
    background: #fff;

    > .ant-collapse-item {
      border: 0;

      & > .ant-collapse-header {
        padding: 0;
        display: flex;
        align-items: center;
      }

      > .ant-collapse-content {
        .ant-collapse-content-box {
          padding: 0;
        }
      }
    }
  }

  .help {
    margin-bottom: 15px;
    color: $gray-color;
  }

  .c-item {
    border: 1px solid #dddcda;
    background: #fafafa;
    display: flex;
    padding: 5px 10px;
    border-radius: 4px;
    align-items: center;
    margin-bottom: 5px;
    height: 40px;
  }

  .c-settings {
    display: flex;
    margin-top: 10px;
    justify-content: space-between;

    &-text {
      color: $gray-color;
      margin-bottom: 5px;
    }

    &-ctrl {
      height: 32px;
      display: flex;
      align-items: center;
    }

    .c-selector {
      height: 32px;
    }
  }

  .fields {
    ul {
      max-height: 420px;
      overflow: auto;
      margin-bottom: 20px;
    }

    .i-drag {
      display: none;
    }
  }

  .color {
    &-type {
      height: 30px;
      display: flex;
      align-items: center;

      .icon {
        margin-right: 7px;
      }
    }

    &-filter {
      padding: 20px;
      min-width: 300px;
      box-sizing: border-box;
    }

    &-fields {
      &-settings {
        .ant-collapse {
          > .ant-collapse-item > .ant-collapse-header {
            padding: 0;
            display: flex;
            flex-wrap: nowrap;
            align-items: center;
          }

          .color-picker {
            margin: 0 10px;
          }
        }
      }
    }

    &-cond {
      margin: 20px 0;

      &-item {
        display: flex;
        align-items: center;
        margin-bottom: 10px;

        .remove {
          height: 30px;
          flex: 0 0 30px;
        }

        .fs-wrap {
          flex: 0 0 250px;
          overflow: hidden;
          margin-right: 5px;
        }

        .fs {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          display: block;
        }
      }

      &-text {
        flex: 1 1 auto;
        display: flex;
        overflow: hidden;
        align-items: center;
        justify-content: space-between;
        cursor: pointer;

        > span {
          padding: 0 3px;
        }

        .e-btn {
          flex: 0 0 30px;
          margin: 0 10px;
          height: 30px;
        }
      }
    }

  }

  .color-picker {
    margin-right: 5px;

    > button {
      height: 32px;
      width: 32px;
    }
  }
}
