.cell-details {
  padding: 20px;
  margin-bottom: 50px;
  display: flex;
  align-items: center;

  .column-def {
    margin-bottom: 15px;
  }

  &-value {
    flex: 1 1 auto;

    .eyzy-textarea {
      height: 50px;
    }
  }

  &-ctrl {
    flex: 0 0 50px;
    align-items: center;
    justify-content: center;
    margin-left: 30px;
  }

  .bo {
    border-radius: 50%;
    border: 1px solid #eee;
    height: 40px;
    width: 40px;
    background: #ececec;

    &:active {
      background: #e4e4e4;
    }
  }

  .i-d-calendar {
    margin-right: 10px;
  }
}
