
.column-settings {
  > .eyzy-input {
    margin-bottom: 20px;
  }

  &-def {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex: 1 1 100%;

    .column-def {
      flex-grow: 0;
    }

    > span {
      font-weight: 600;
    }
  }

  &-body {
    margin-top: 10px;
    padding: 10px 10px 0 10px;
    border-top: 1px solid #e6e3e3;
  }

  &-row, &-crow {
    margin-bottom: 15px;
    display: flex;
    flex-direction: column;
  }

  &-crow {
    flex-direction: row;

    .column-settings-cell:first-child {
      margin-right: 10px;
    }
  }

  &-cell {
    flex: 1 1 48%;
  }

  &-label {
    margin-bottom: 5px;
    color: rgba(0,0,0,.45);
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &-slabel {
    margin-bottom: 5px;
    color: rgba(0,0,0,.45);
  }

  &-ctrl {
    display: flex;
  }

  &-footer {
    margin-top: 30px;
    padding: 0 17px;

    .eyzy-btn {
      margin-right: 7px;
    }
  }

  &-hint {
    font-size: 0.9em;
  }

  &-link {
    min-height: 100px;
    position: relative;
  }

  .half {
    flex: 1 1 50%;
  }

  .half + .half {
    padding-left: 10px;
  }

  .help {
    cursor: pointer;
    margin-left: 7px;
  }
}

.select-opts {
  flex: 1 1 auto;
}

.select-opt-dnd {
  display: flex;
  flex: 1;
  align-items: center;

  > input {
    flex-grow: 1;
    border: 1px solid transparent;
    outline: none;
    height: 30px;
    padding: 4px;
    border-radius: 4px;

    &:hover, &:focus {
      border: 1px solid #e3e3e3;
    }
  }

  .color-picker {
    margin-right: 5px;
  }

  .remove {
    width: 30px;
    height: 30px;
  }
}
