.e-tag {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  line-height: 1em;
  padding: 5px 8px;
  background: var(--irv-tag-bg, var(--irv-base-bg));
  border: 1px solid var(--irv-tag-border, var(--irv-border));
  border-radius: 10px;
  height: 24px;
  box-sizing: border-box;

  &-content {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  &--rounded {
    border-radius: 20px;
  }

  &__remove {
    background: none;
    border: 0;
    width: 16px;
    padding: 0;
    height: 16px;
    margin-left: 5px;
    outline: none;
    cursor: pointer;
  }
}
