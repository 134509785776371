.resp-tooltip {
  &.ant-tooltip {
    max-width: initial;
  }

  .ant-tooltip-inner {
    padding: 0;
    border: 1px solid #dadada;
    background: #fff;
    color: #343434;
    font-size: 0.9em;
    position: relative;
  }

  .ant-tooltip-arrow {
    display: none;
  }
}
