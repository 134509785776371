.row-details-container {
  width: 100%;
  overflow: auto;
  height: 100%;

  .row-details-form {
    margin: 50px auto;
    max-width: 800px;
  }

  .lookup-value {
    > div:not(:last-child) {
      margin-bottom: 10px;
    }
  }

  .irv-frm {
    width: 50%;
    background: #fff;
    padding: 20px;
    border-radius: 4px;
    box-shadow: 0 0 4px 0 rgb(0 0 0 / 10%);

    &-logo {
      max-width: 40%;
      margin: 0 auto;

      img {
        width: 100%;
      }
    }

    &-header {
      h2 {
        font-size: 32px;
        margin: 20px 0 10px 0;
      }
    }

    &-description {
      margin: 10px 0;
      color: #454545;
    }
  }

  &.details-layout-1 {
    .irv-frm {
      min-height: 100%;
      box-shadow: none;
      border-radius: 0;
    }
  }

  &.details-layout-2 {
    .irv-frm {
      margin: 70px;
      width: 70%;
      max-width: 680px;
    }
  }

  &.details-layout-3 {
    .irv-frm {
      margin: 70px auto;
      width: 70%;
      max-width: 680px;
    }
  }

  &.details-layout-4 {
    .irv-frm {
      width: 70%;
      max-width: 680px;
      margin: 70px 70px 70px auto;
    }
  }

  &.details-layout-5 {
    .irv-frm {
      border-radius: 0;
      box-shadow: none;
      min-height: 100%;
      margin-left: auto;
    }
  }
}

@media (max-width: 768px) {
  .row-details-container {
    &.details-layout-2, &.details-layout-3, &.details-layout-4 {
      .irv-frm {
        width: 90%;
        margin: 30px auto;
      }
    }

    .irv-frm {
      width: 90%;
      margin-left: auto;
      margin-right: auto;
    }
  }
}

@media (max-width: 900px) {
  .row-details-container {
    .row-details-form {
      width: 80%;
    }
  }
}
