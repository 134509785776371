
.user-select-opt {
  display: flex;
  align-items: center;
  flex: 1 1 100%;
  padding: 0 15px;
  cursor: default;

  &:hover {
    .c {
      display: block;
    }
  }

  .avatar {
    margin-right: 10px;
  }

  .info {
    display: flex;
    flex-direction: column;
    padding: 10px 3px;
  }

  .name {
    font-size: 1.1em;
    font-weight: 500;
  }

  .email {
    color: #838383;
  }

  .c {
    width: 4px;
    height: 100%;
    position: absolute;
    left: 0;
    display: none;
  }
}
