@import "../../../../../assets/style/variables";

.mob-event-details {
  &.ant-drawer-bottom.ant-drawer-open .ant-drawer-content-wrapper {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }

  .ant-drawer {
    &-header {
      padding: 10px;
    }

    &-title {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .eyzy-btn {
        font-weight: 400;
        color: $mobileBtnColor;
      }
    }

    &-body {
      background-color: $mobileFormBG;
      padding: 24px 18px;
    }
  }

  .react-datepicker {
    box-shadow: none;
    width: 253px;

    &__navigation {
      &-icon {
        &:before {
          top: 12px;
        }
      }
    }
  }

  .event-date {
    flex-direction: column;

    &-selectors {
      display: flex;
      justify-content: space-between;
      width: 100%;

      .form-item-label {
        flex: 0 1 auto;
        overflow: hidden;
        margin-right: 5px;
      }

      .form-item-ctrl {
        flex: 0 0 190px;
      }
    }

    input {
      outline: none;
      background-color: $mobileCtrlBG;
      height: 34px;
      border-radius: 6px;
      padding: 0 10px;
      opacity: 1;
      color: #000;

      &.active, &:active {
        background-color: darken($mobileCtrlBG, 10);
      }
    }

    .date {
      width: 130px;
      text-align: center;
    }

    .time {
      margin-left: 6px;

      width: 55px;
      border-radius: 6px;
    }

    .form-item-foo {
      display: flex;
      justify-content: center;
      flex: 0;
      margin: 0;
      transition: all 0.2s ease-out;
      overflow: hidden;

      &.open {
        flex: 1 0 240px;
        margin-top: 20px;
      }
    }
  }

  // TODO: REMOVE
  .calendar-weekdays {
    margin-top: 20px;
  }

  .rm {
    width: 100%;
    margin-top: 30px;
    background: #fff;
    color: red;
    height: 32px;

    &:active {
      background: red;
    }
  }
}


.mdt-selector {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  overflow: hidden;
  width: 105px;
  height: 240px;

  .selectors {
    margin-left: 10px;
    height: 100%;
    display: flex;
  }

  ul {
    flex: 1 1 auto;
    text-align: center;
    overflow: auto;
    height: 100%;
  }

  li {
    line-height: 35px;
    border-radius: 6px;

    &.active {
      background-color: #216ba5;
      color: white;
      font-weight: bold;
    }
  }

  .mdt-hours {
    li {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
  }

  .mdt-mins {
    li {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  }
}
