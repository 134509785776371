@mixin flex() {
  display: flex;
  justify-content: center;
  align-items: center;
}

@mixin flex-inline() {
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

@mixin add-base-border() {
  border-radius: $border-radius;
  border: $border-width solid $input-border-color;
}

@mixin add-base-height() {
  height: $ctrl-height;
  line-height: $ctrl-line-height;
  box-sizing: border-box;
}