.grid-mask__active {
  .ant-select-selection-item {
    .eyzy-tag {
      color: #000;
    }
  }
}

.grid-editor {
  &.ant-select, &.ant-calendar-picker {
    border: 0;
  }

  &.ant-select {
    height: auto;
    min-height: 100%;
    padding: 0;
    width: 100%;

    &-show-search {
      &.ant-select-open {
        .ant-select-selection-item .eyzy-tag {
          background: none;
        }
      }
    }
  }

  &.ant-calendar-picker .ant-calendar-picker-input {
    height: 28px;
    border-radius: 0;
  }

  &.ant-select .ant-select-selection--multiple {
    border: 2px solid #2d7ff9;
    position: absolute;
    left: -2px;
    top: -2px;
    width: 100%;
  }
}

.grid-cell .ant-badge-not-a-wrapper .ant-badge-count {
  z-index: 1;
}

.grid-mask__fullwidth {
  position: relative;
  height: 32px;
}

.grid .grid-mask__active {
  .ant-select {
    position: relative;
    top: -2px;
    left: -2px;
    border: 2px solid #2d7ff9;

    .ant-select-selector {
      border: 0;
      height: 100%;
      cursor: pointer;
      padding: 0 6px;
    }

    .ant-select-selection-item {
      display: flex;
      align-items: center;
    }

    .eyzy-tag {
      font-size: 13px;
    }
  }
}

.resp_notification.ant-notification-notice {
  background: hsl(0, 0%, 20%);

  .ant-notification-notice-message, .ant-notification-notice-close {
    color: #fff;
  }
}

.ant-collapse-content-box {
  position: relative;
}

//
//.ant-radio {
//  &-group {
//    background: #efefef;
//    padding: 2px;
//    border-radius: 4px;
//
//    &-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
//      background: #fff;
//      color: #333333;
//
//      &:first-child {
//        border-color: #d9d9d9;
//      }
//    }
//  }
//
//  &-button-wrapper {
//    background: #eee;
//  }
//
//  &-button-wrapper-checked {
//  }
//}

.ant-dropdown {
  &-menu-item {
    display: flex;
    align-items: center;
  }
}

.ant-menu {
  &-item {
    display: flex;
    align-items: center;

    .icon {
      margin-right: 10px;
    }
  }
}

.wtt.ant-tooltip {
  max-width: unset;

  .ant-tooltip-inner {
    padding: 0;
    background: #fff;
    color: #343434;
    font-size: 0.9em;
    position: relative;
    border-radius: 4px;
  }

  .ant-tooltip-arrow {
    display: none;
  }
}

.wtm-menu {
  .ant-menu {
    padding: 6px;
    max-height: 600px;
    border-radius: 4px;

    &-item {
      height: 30px;
      line-height: 30px;
      color: rgba(0, 0, 0, 0.85);
      display: flex;
      align-items: center;
      border-radius: 4px;
      padding: 0 15px;

      &:not(:last-child), &:last-child {
        margin: 0;
      }

      &:hover {
        color: rgba(0, 0, 0, 0.85);
        background-color: #eee;
      }

      &-group-title {
        padding: 6px 8px;
      }
    }

    .ant-menu-item-group + .ant-menu-item-group {
      margin-top: 20px;
    }
  }
}

.wtm.ant-menu-vertical {
  border: 0;
  border-radius: 4px;

  > .ant-menu-submenu {
    > .ant-menu-submenu-title {
      height: 32px;
      line-height: 28px;
      color: rgba(0, 0, 0, 0.85);
      border-radius: 4px;
      display: flex;
      align-items: center;
      margin: 0;
      padding-left: 10px;

      .ant-menu-submenu-arrow {
        color: rgba(0, 0, 0, 0.85);
      }

      &:hover {
        color: rgba(0, 0, 0, 0.85);
        background-color: #eee;
      }
    }

    &.ant-menu-submenu-active {
      > .ant-menu-submenu-title {
        background-color: #eee;
      }
    }
  }
}


.c-selector {
  border: 1px solid #dddcda;
  background: #fafafa;
  display: flex;
  border-radius: 4px;
  align-items: center;
  margin-bottom: 5px;
  min-height: 32px;
  padding: 0;
  position: relative;

  .eyzy-btn-minimal {
    width: 28px;
    height: 28px;
    position: absolute;
    right: 10px;
  }

  .ant-select {
    width: 100%;
  }

  .ant-select-selection-item {
    display: flex;
    align-items: center;
  }

  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border: 0;
    background: transparent;
  }

  .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border: 0;
    box-shadow: none;
  }
}

@media (max-width: 767px) {
  .ant-select-selection-search-input {
    display: none;
  }
}


